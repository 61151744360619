import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { Button } from '$ui/index';
import { ClockCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import { Logo, UserProfile, SkeletonUserProfile, MobileMenu, NoticeGDPR } from './components';

import { HeaderProps } from './types';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './Header.module.less';

const Header = ({ currentUser, loading, searchParamsForLinkBuyBoats }: HeaderProps) => {
  const { generatePath } = useGeneratePath();

  return (
    <div className={commonStyles.container}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <Logo />
        </div>

        <MediaQuery minWidth={991}>
          <div className={styles.linkProvidedForBoatList}>
            <Link
              to={{
                pathname: generatePath(routes.BOATS),
                search: searchParamsForLinkBuyBoats,
              }}
              className={styles.linkBuyBoats}
            >
              <Button type="link" shape="round" className={styles.linkCA}>
                <ClockCircleOutlined />
                Charter
              </Button>
            </Link>
            <Link
              to={{
                pathname: generatePath(routes.BUY_BOATS),
                search: searchParamsForLinkBuyBoats,
              }}
              className={styles.linkBuyBoats}
            >
              <Button type="link" shape="round" className={styles.linkCA}>
                <DollarCircleOutlined />
                Sales
              </Button>
            </Link>
          </div>

          <div className={styles.buttonListYourBoat}>
            {currentUser?.accessAdmin ? (
              <a href="https://getboat.com/admin/#/Boat" target="_blank" rel="noreferrer">
                <Button type="link" shape="round" className={styles.linkCA}>
                  List my boat
                </Button>
              </a>
            ) : (
              <Link
                to={{
                  pathname: generatePath(routes.SIGN_UP),
                  search: '?iam=ca',
                }}
              >
                <Button type="link" shape="round" className={styles.linkCA}>
                  List my boat
                </Button>
              </Link>
            )}
          </div>

          <Link to={{ pathname: generatePath(routes.RATES) }}>
            <Button type="link" shape="round" className={styles.linkCA}>
              Sell my boat
            </Button>
          </Link>
        </MediaQuery>

        {/*<LanguageSelector className={styles.languageSelector} />*/}
        {loading ? <SkeletonUserProfile /> : <UserProfile currentUser={currentUser} />}
        <MobileMenu />
        <NoticeGDPR />
      </div>
    </div>
  );
};

export default Header;
