/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const AvailableBoatItemsFragmentDoc = gql`
  fragment AvailableBoatItems on AvailableBoatItems {
    items {
      id
      slug
      name
      location {
        address
        country {
          name
        }
      }
      pictures {
        md
      }
      builtAt
      rating
      length
      persons
      cabins
      berths
      crewTotal
      boatProvidedFor
      exclusivePrice {
        currency
        pricePerHour
        pricePerDay
        pricePerWeek
      }
      salePrice {
        currency
        value
      }
    }
    meta {
      captain
      availableCategories {
        id
        name
        picture {
          w85
          w170
          w255
          w340
        }
        price {
          amount
        }
      }
      availableUrlCategories {
        id
        url
      }
      selectedCategories {
        id
      }
      location {
        id
        address
        slug
        type
        country {
          id
          name
          code
        }
        state {
          id
          address
        }
      }
      page
      perPage
      totalCount
      totalPages
      url
    }
  }
`;
export const BoatTopReviewItemsFragmentDoc = gql`
  fragment BoatTopReviewItems on BoatTopReviewItems {
    items {
      id
      rating
      enContent
      createdAt
      boat {
        id
        name
        pictures {
          md
        }
      }
      account {
        id
        fullName
      }
    }
    meta {
      locationAverageRating
      totalCount
      totalCountReviews
    }
  }
`;
export const BoatFullFragmentDoc = gql`
  fragment BoatFull on Boat {
    id
    slug
    slugs {
      country
      locationAndCategory
    }
    name
    location {
      id
      address
      slug
      latitude
      longitude
      country {
        name
      }
    }
    pictures {
      lg
      md
      sm
      thumb
      w680
      w1360
      w2040
      w100
      w300
    }
    rating
    description
    length
    persons
    cabins
    berths
    crewTotal
    builtAt
    boatProvidedFor
    boatCategory {
      id
      name
    }
    exclusivePrice {
      currency
      pricePerHour
      pricePerDay
      pricePerWeek
    }
    salePrice {
      currency
      value
    }
    boatAdditionalInfo {
      accommodationAndCrew
      amenities
      dimensions
      equipment
      generalInformation
      performance
      rentPrice
      tendersAndToys
    }
    boatProducts {
      id
      productName
      priceEur
      unit
    }
    cancellationPolicy
  }
`;
export const CharterFragmentDoc = gql`
  fragment Charter on Charter {
    id
    locations {
      id
      name
      location {
        id
        address
      }
    }
    startsAt
    endsAt
    promoCodeOriginal
    promoCode {
      discountPercentage
      promoCode
    }
    offers {
      id
    }
    boat {
      ...BoatFull
    }
  }
  ${BoatFullFragmentDoc}
`;
export const ChartersFragmentDoc = gql`
  fragment Charters on Charter {
    id
    locations {
      id
      name
      location {
        id
        address
      }
    }
    startsAt
    endsAt
    promoCodeOriginal
    promoCode {
      discountPercentage
      promoCode
    }
    createdAt
    offers {
      id
      status
    }
    createdBy
  }
`;
export const LocationInfoFragmentDoc = gql`
  fragment LocationInfo on LocationInfo {
    location {
      id
      address
      slug
    }
    locationDescription {
      enContent
    }
    faqs {
      id
      enAnswer
      enQuestion
    }
    destinations {
      id
      address
      slug
      previewImages {
        x1
        x2
        x3
        x4
      }
    }
  }
`;
export const LocationSuggestionFragmentDoc = gql`
  fragment LocationSuggestion on Location {
    address
    country {
      id
      name
      code
    }
    id
    type
    slug
  }
`;
export const OfferFragmentDoc = gql`
  fragment Offer on Offer {
    id
    prepayPercent
    firstPaymentAmount
    secondPaymentAmount
    secondPaymentPercent
    payTillOn
    startAt
    endAt
    status
    location {
      address
      country {
        name
      }
    }
    payments {
      id
      status
      type
      redirectUrl
      token
      paymentMethod {
        id
        type
      }
      amount {
        amount
        currency
      }
    }
    price {
      amount
      currency
    }
    priceWithoutDiscount {
      amount
      currency
    }
    charter {
      id
      startsAt
      endsAt
      promoCodeOriginal
      promoCode {
        discountPercentage
        promoCode
      }
      guests
      captain
      comment
      locations {
        id
        name
        location {
          id
          address
        }
      }
      phones
    }
    account {
      fullName
      cellphone
      email
    }
    centralAgent {
      accountPhone
      companyPhone
      email
      joinedDate
      name
    }
    boat {
      ...BoatFull
    }
  }
  ${BoatFullFragmentDoc}
`;
export const BoatShortFragmentDoc = gql`
  fragment BoatShort on Boat {
    id
    slug
    name
    location {
      address
      country {
        name
      }
    }
    pictures {
      md
      w280
      w560
      w840
    }
    builtAt
    rating
    length
    persons
    cabins
    berths
    crewTotal
    boatProvidedFor
    exclusivePrice {
      currency
      pricePerHour
      pricePerDay
      pricePerWeek
    }
    salePrice {
      currency
      value
    }
  }
`;
export const BoatFragmentDoc = gql`
  fragment Boat on Charter {
    boat {
      ...BoatShort
    }
  }
  ${BoatShortFragmentDoc}
`;
export const SearchBoatsFragmentDoc = gql`
  fragment SearchBoats on Boat {
    ...BoatShort
  }
  ${BoatShortFragmentDoc}
`;
export const CurrentUserLocalDocument = gql`
  query CurrentUserLocal {
    currentUser @client {
      id
      isLoggedIn
      email
      accessAdmin
      isCentralAgent
      isAdmin
      isPaidSubscription
      isSeniorAccount
      isPartnerAccount
    }
  }
`;

/**
 * __useCurrentUserLocalQuery__
 *
 * To run a query within a React component, call `useCurrentUserLocalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserLocalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserLocalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserLocalQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CurrentUserLocalQuery, Types.CurrentUserLocalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CurrentUserLocalQuery, Types.CurrentUserLocalQueryVariables>(
    CurrentUserLocalDocument,
    options
  );
}
export function useCurrentUserLocalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CurrentUserLocalQuery, Types.CurrentUserLocalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CurrentUserLocalQuery, Types.CurrentUserLocalQueryVariables>(
    CurrentUserLocalDocument,
    options
  );
}
export type CurrentUserLocalQueryHookResult = ReturnType<typeof useCurrentUserLocalQuery>;
export type CurrentUserLocalLazyQueryHookResult = ReturnType<typeof useCurrentUserLocalLazyQuery>;
export type CurrentUserLocalQueryResult = Apollo.QueryResult<
  Types.CurrentUserLocalQuery,
  Types.CurrentUserLocalQueryVariables
>;
export function refetchCurrentUserLocalQuery(variables?: Types.CurrentUserLocalQueryVariables) {
  return { query: CurrentUserLocalDocument, variables: variables };
}
export const AddChareterDocument = gql`
  mutation addChareter(
    $phones: [String!]
    $email: String
    $comment: String
    $transferNeeded: Boolean
    $watersports: Boolean
    $captain: Boolean
    $boatCategoryIds: [ID!]
    $guests: Int
    $promoCodeOriginal: String
    $priceMax: Int
    $currency: String
    $locationName: String
    $locationId: ID
    $lang: String
    $boatId: ID
    $startsAt: String
    $endsAt: String
    $flexibleDates: Boolean
    $hoursFrom: Int
    $hoursTo: Int
    $utmMedium: String
    $utmSource: String
    $utmCampaign: String
    $utmContent: String
    $utmTerm: String
    $utmTrafficSource: String
    $utmReferralLink: String
  ) {
    addCharter(
      phones: $phones
      email: $email
      comment: $comment
      transferNeeded: $transferNeeded
      watersports: $watersports
      captain: $captain
      boatCategoryIds: $boatCategoryIds
      guests: $guests
      promoCodeOriginal: $promoCodeOriginal
      priceMax: $priceMax
      currency: $currency
      locationName: $locationName
      locationId: $locationId
      lang: $lang
      boatId: $boatId
      startsAt: $startsAt
      endsAt: $endsAt
      flexibleDates: $flexibleDates
      hoursFrom: $hoursFrom
      hoursTo: $hoursTo
      utmMedium: $utmMedium
      utmSource: $utmSource
      utmCampaign: $utmCampaign
      utmContent: $utmContent
      utmTerm: $utmTerm
      utmTrafficSource: $utmTrafficSource
      utmReferralLink: $utmReferralLink
    ) {
      id
      boat {
        id
        name
      }
      price {
        amount
        currency
      }
    }
  }
`;
export type AddChareterMutationFn = Apollo.MutationFunction<
  Types.AddChareterMutation,
  Types.AddChareterMutationVariables
>;

/**
 * __useAddChareterMutation__
 *
 * To run a mutation, you first call `useAddChareterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddChareterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addChareterMutation, { data, loading, error }] = useAddChareterMutation({
 *   variables: {
 *      phones: // value for 'phones'
 *      email: // value for 'email'
 *      comment: // value for 'comment'
 *      transferNeeded: // value for 'transferNeeded'
 *      watersports: // value for 'watersports'
 *      captain: // value for 'captain'
 *      boatCategoryIds: // value for 'boatCategoryIds'
 *      guests: // value for 'guests'
 *      promoCodeOriginal: // value for 'promoCodeOriginal'
 *      priceMax: // value for 'priceMax'
 *      currency: // value for 'currency'
 *      locationName: // value for 'locationName'
 *      locationId: // value for 'locationId'
 *      lang: // value for 'lang'
 *      boatId: // value for 'boatId'
 *      startsAt: // value for 'startsAt'
 *      endsAt: // value for 'endsAt'
 *      flexibleDates: // value for 'flexibleDates'
 *      hoursFrom: // value for 'hoursFrom'
 *      hoursTo: // value for 'hoursTo'
 *      utmMedium: // value for 'utmMedium'
 *      utmSource: // value for 'utmSource'
 *      utmCampaign: // value for 'utmCampaign'
 *      utmContent: // value for 'utmContent'
 *      utmTerm: // value for 'utmTerm'
 *      utmTrafficSource: // value for 'utmTrafficSource'
 *      utmReferralLink: // value for 'utmReferralLink'
 *   },
 * });
 */
export function useAddChareterMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddChareterMutation, Types.AddChareterMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddChareterMutation, Types.AddChareterMutationVariables>(
    AddChareterDocument,
    options
  );
}
export type AddChareterMutationHookResult = ReturnType<typeof useAddChareterMutation>;
export type AddChareterMutationResult = Apollo.MutationResult<Types.AddChareterMutation>;
export type AddChareterMutationOptions = Apollo.BaseMutationOptions<
  Types.AddChareterMutation,
  Types.AddChareterMutationVariables
>;
export const AddPlatronPaymentDocument = gql`
  mutation addPlatronPayment($offerId: ID!, $amount: Float!, $currency: String!) {
    addPlatronPayment(offerId: $offerId, amount: $amount, currency: $currency) {
      id
      status
      redirectUrl
    }
  }
`;
export type AddPlatronPaymentMutationFn = Apollo.MutationFunction<
  Types.AddPlatronPaymentMutation,
  Types.AddPlatronPaymentMutationVariables
>;

/**
 * __useAddPlatronPaymentMutation__
 *
 * To run a mutation, you first call `useAddPlatronPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlatronPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlatronPaymentMutation, { data, loading, error }] = useAddPlatronPaymentMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      amount: // value for 'amount'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAddPlatronPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddPlatronPaymentMutation, Types.AddPlatronPaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddPlatronPaymentMutation, Types.AddPlatronPaymentMutationVariables>(
    AddPlatronPaymentDocument,
    options
  );
}
export type AddPlatronPaymentMutationHookResult = ReturnType<typeof useAddPlatronPaymentMutation>;
export type AddPlatronPaymentMutationResult = Apollo.MutationResult<Types.AddPlatronPaymentMutation>;
export type AddPlatronPaymentMutationOptions = Apollo.BaseMutationOptions<
  Types.AddPlatronPaymentMutation,
  Types.AddPlatronPaymentMutationVariables
>;
export const AddStripePaymentDocument = gql`
  mutation addStripePayment($offerId: ID!, $amount: Float!, $currency: String!) {
    addStripePayment(offerId: $offerId, amount: $amount, currency: $currency) {
      id
      status
    }
  }
`;
export type AddStripePaymentMutationFn = Apollo.MutationFunction<
  Types.AddStripePaymentMutation,
  Types.AddStripePaymentMutationVariables
>;

/**
 * __useAddStripePaymentMutation__
 *
 * To run a mutation, you first call `useAddStripePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStripePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStripePaymentMutation, { data, loading, error }] = useAddStripePaymentMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      amount: // value for 'amount'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useAddStripePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddStripePaymentMutation, Types.AddStripePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddStripePaymentMutation, Types.AddStripePaymentMutationVariables>(
    AddStripePaymentDocument,
    options
  );
}
export type AddStripePaymentMutationHookResult = ReturnType<typeof useAddStripePaymentMutation>;
export type AddStripePaymentMutationResult = Apollo.MutationResult<Types.AddStripePaymentMutation>;
export type AddStripePaymentMutationOptions = Apollo.BaseMutationOptions<
  Types.AddStripePaymentMutation,
  Types.AddStripePaymentMutationVariables
>;
export const AddWebPushTokenDocument = gql`
  mutation addWebPushToken($token: String!) {
    addWebPushToken(token: $token) {
      success
    }
  }
`;
export type AddWebPushTokenMutationFn = Apollo.MutationFunction<
  Types.AddWebPushTokenMutation,
  Types.AddWebPushTokenMutationVariables
>;

/**
 * __useAddWebPushTokenMutation__
 *
 * To run a mutation, you first call `useAddWebPushTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWebPushTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWebPushTokenMutation, { data, loading, error }] = useAddWebPushTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAddWebPushTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.AddWebPushTokenMutation, Types.AddWebPushTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.AddWebPushTokenMutation, Types.AddWebPushTokenMutationVariables>(
    AddWebPushTokenDocument,
    options
  );
}
export type AddWebPushTokenMutationHookResult = ReturnType<typeof useAddWebPushTokenMutation>;
export type AddWebPushTokenMutationResult = Apollo.MutationResult<Types.AddWebPushTokenMutation>;
export type AddWebPushTokenMutationOptions = Apollo.BaseMutationOptions<
  Types.AddWebPushTokenMutation,
  Types.AddWebPushTokenMutationVariables
>;
export const BuyBoatDocument = gql`
  mutation buyBoat(
    $userName: String!
    $userEmail: String!
    $userPhone: String!
    $userPrice: String
    $boatPrice: Float!
    $boatPriceCurrency: String!
    $boatId: ID!
    $boatLink: String!
    $utms: JSON!
  ) {
    buyBoat(
      userName: $userName
      userEmail: $userEmail
      userPhone: $userPhone
      userPrice: $userPrice
      boatPrice: $boatPrice
      boatPriceCurrency: $boatPriceCurrency
      boatId: $boatId
      boatLink: $boatLink
      utms: $utms
    ) {
      success
    }
  }
`;
export type BuyBoatMutationFn = Apollo.MutationFunction<Types.BuyBoatMutation, Types.BuyBoatMutationVariables>;

/**
 * __useBuyBoatMutation__
 *
 * To run a mutation, you first call `useBuyBoatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyBoatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyBoatMutation, { data, loading, error }] = useBuyBoatMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      userEmail: // value for 'userEmail'
 *      userPhone: // value for 'userPhone'
 *      userPrice: // value for 'userPrice'
 *      boatPrice: // value for 'boatPrice'
 *      boatPriceCurrency: // value for 'boatPriceCurrency'
 *      boatId: // value for 'boatId'
 *      boatLink: // value for 'boatLink'
 *      utms: // value for 'utms'
 *   },
 * });
 */
export function useBuyBoatMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.BuyBoatMutation, Types.BuyBoatMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.BuyBoatMutation, Types.BuyBoatMutationVariables>(BuyBoatDocument, options);
}
export type BuyBoatMutationHookResult = ReturnType<typeof useBuyBoatMutation>;
export type BuyBoatMutationResult = Apollo.MutationResult<Types.BuyBoatMutation>;
export type BuyBoatMutationOptions = Apollo.BaseMutationOptions<Types.BuyBoatMutation, Types.BuyBoatMutationVariables>;
export const CallMeRequestDocument = gql`
  mutation callMeRequest($phone: String!) {
    callMeRequest(phone: $phone) {
      success
    }
  }
`;
export type CallMeRequestMutationFn = Apollo.MutationFunction<
  Types.CallMeRequestMutation,
  Types.CallMeRequestMutationVariables
>;

/**
 * __useCallMeRequestMutation__
 *
 * To run a mutation, you first call `useCallMeRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallMeRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callMeRequestMutation, { data, loading, error }] = useCallMeRequestMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useCallMeRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.CallMeRequestMutation, Types.CallMeRequestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CallMeRequestMutation, Types.CallMeRequestMutationVariables>(
    CallMeRequestDocument,
    options
  );
}
export type CallMeRequestMutationHookResult = ReturnType<typeof useCallMeRequestMutation>;
export type CallMeRequestMutationResult = Apollo.MutationResult<Types.CallMeRequestMutation>;
export type CallMeRequestMutationOptions = Apollo.BaseMutationOptions<
  Types.CallMeRequestMutation,
  Types.CallMeRequestMutationVariables
>;
export const ChangeUserInterestDocument = gql`
  mutation changeUserInterest($offerId: ID!, $value: Boolean!) {
    changeUserInterest(offerId: $offerId, value: $value) {
      success
      offerId
      value
    }
  }
`;
export type ChangeUserInterestMutationFn = Apollo.MutationFunction<
  Types.ChangeUserInterestMutation,
  Types.ChangeUserInterestMutationVariables
>;

/**
 * __useChangeUserInterestMutation__
 *
 * To run a mutation, you first call `useChangeUserInterestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserInterestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserInterestMutation, { data, loading, error }] = useChangeUserInterestMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useChangeUserInterestMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ChangeUserInterestMutation, Types.ChangeUserInterestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ChangeUserInterestMutation, Types.ChangeUserInterestMutationVariables>(
    ChangeUserInterestDocument,
    options
  );
}
export type ChangeUserInterestMutationHookResult = ReturnType<typeof useChangeUserInterestMutation>;
export type ChangeUserInterestMutationResult = Apollo.MutationResult<Types.ChangeUserInterestMutation>;
export type ChangeUserInterestMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeUserInterestMutation,
  Types.ChangeUserInterestMutationVariables
>;
export const CreateCheckoutSessionDocument = gql`
  mutation createCheckoutSession($lookupKey: String!) {
    createCheckoutSession(lookupKey: $lookupKey) {
      sessionUrl
    }
  }
`;
export type CreateCheckoutSessionMutationFn = Apollo.MutationFunction<
  Types.CreateCheckoutSessionMutation,
  Types.CreateCheckoutSessionMutationVariables
>;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutSessionMutation, { data, loading, error }] = useCreateCheckoutSessionMutation({
 *   variables: {
 *      lookupKey: // value for 'lookupKey'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateCheckoutSessionMutation,
    Types.CreateCheckoutSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.CreateCheckoutSessionMutation, Types.CreateCheckoutSessionMutationVariables>(
    CreateCheckoutSessionDocument,
    options
  );
}
export type CreateCheckoutSessionMutationHookResult = ReturnType<typeof useCreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationResult = Apollo.MutationResult<Types.CreateCheckoutSessionMutation>;
export type CreateCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateCheckoutSessionMutation,
  Types.CreateCheckoutSessionMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation resetPassword(
    $password: String!
    $passwordConfirmation: String!
    $accountId: Int!
    $confirmationCode: String!
  ) {
    passwordReset(
      password: $password
      passwordConfirmation: $passwordConfirmation
      accountId: $accountId
      confirmationCode: $confirmationCode
    )
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      accountId: // value for 'accountId'
 *      confirmationCode: // value for 'confirmationCode'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(
    ResetPasswordDocument,
    options
  );
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;
export const SignInDocument = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      id
      token
      isCentralAgent
      isAdmin
      isPaidSubscription
      isSeniorAccount
      isPartnerAccount
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<Types.SignInMutation, Types.SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SignInMutation, Types.SignInMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SignInMutation, Types.SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<Types.SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<Types.SignInMutation, Types.SignInMutationVariables>;
export const SignUpDocument = gql`
  mutation signUp(
    $email: String!
    $fullName: String
    $password: String!
    $passwordConfirmation: String!
    $cellphone: String
  ) {
    signUp(
      email: $email
      fullName: $fullName
      cellphone: $cellphone
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      id
      email
      token
      isCentralAgent
      isAdmin
      isPaidSubscription
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<Types.SignUpMutation, Types.SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      cellphone: // value for 'cellphone'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SignUpMutation, Types.SignUpMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SignUpMutation, Types.SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<Types.SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<Types.SignUpMutation, Types.SignUpMutationVariables>;
export const SignUpCaDocument = gql`
  mutation signUpCa(
    $email: String!
    $fullName: String
    $password: String!
    $passwordConfirmation: String!
    $cellphone: String
    $companyName: String!
    $vatNumber: String!
  ) {
    signUpCa(
      email: $email
      fullName: $fullName
      cellphone: $cellphone
      password: $password
      passwordConfirmation: $passwordConfirmation
      companyName: $companyName
      vatNumber: $vatNumber
    ) {
      id
      email
      token
      isCentralAgent
      isAdmin
      isPaidSubscription
    }
  }
`;
export type SignUpCaMutationFn = Apollo.MutationFunction<Types.SignUpCaMutation, Types.SignUpCaMutationVariables>;

/**
 * __useSignUpCaMutation__
 *
 * To run a mutation, you first call `useSignUpCaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpCaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpCaMutation, { data, loading, error }] = useSignUpCaMutation({
 *   variables: {
 *      email: // value for 'email'
 *      fullName: // value for 'fullName'
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      cellphone: // value for 'cellphone'
 *      companyName: // value for 'companyName'
 *      vatNumber: // value for 'vatNumber'
 *   },
 * });
 */
export function useSignUpCaMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.SignUpCaMutation, Types.SignUpCaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.SignUpCaMutation, Types.SignUpCaMutationVariables>(SignUpCaDocument, options);
}
export type SignUpCaMutationHookResult = ReturnType<typeof useSignUpCaMutation>;
export type SignUpCaMutationResult = Apollo.MutationResult<Types.SignUpCaMutation>;
export type SignUpCaMutationOptions = Apollo.BaseMutationOptions<
  Types.SignUpCaMutation,
  Types.SignUpCaMutationVariables
>;
export const UpdatePaymentDocument = gql`
  mutation updatePayment($id: ID!, $status: String!) {
    updatePayment(id: $id, status: $status) {
      id
      status
    }
  }
`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<
  Types.UpdatePaymentMutation,
  Types.UpdatePaymentMutationVariables
>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<Types.UpdatePaymentMutation, Types.UpdatePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Types.UpdatePaymentMutation, Types.UpdatePaymentMutationVariables>(
    UpdatePaymentDocument,
    options
  );
}
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<Types.UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdatePaymentMutation,
  Types.UpdatePaymentMutationVariables
>;
export const AvailableBoatsDocument = gql`
  query availableBoats(
    $locationId: ID
    $boatCategories: [ID!]
    $perPage: Int
    $page: Int
    $hasPhoto: String
    $dateFrom: ISO8601DateTime
    $dateTo: ISO8601DateTime
    $captain: String
    $url: String
  ) {
    availableBoats(
      locationId: $locationId
      boatCategories: $boatCategories
      perPage: $perPage
      page: $page
      hasPhoto: $hasPhoto
      dateFrom: $dateFrom
      dateTo: $dateTo
      captain: $captain
      url: $url
    ) {
      ...AvailableBoatItems
    }
  }
  ${AvailableBoatItemsFragmentDoc}
`;

/**
 * __useAvailableBoatsQuery__
 *
 * To run a query within a React component, call `useAvailableBoatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBoatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBoatsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      boatCategories: // value for 'boatCategories'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      hasPhoto: // value for 'hasPhoto'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      captain: // value for 'captain'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useAvailableBoatsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.AvailableBoatsQuery, Types.AvailableBoatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AvailableBoatsQuery, Types.AvailableBoatsQueryVariables>(
    AvailableBoatsDocument,
    options
  );
}
export function useAvailableBoatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableBoatsQuery, Types.AvailableBoatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.AvailableBoatsQuery, Types.AvailableBoatsQueryVariables>(
    AvailableBoatsDocument,
    options
  );
}
export type AvailableBoatsQueryHookResult = ReturnType<typeof useAvailableBoatsQuery>;
export type AvailableBoatsLazyQueryHookResult = ReturnType<typeof useAvailableBoatsLazyQuery>;
export type AvailableBoatsQueryResult = Apollo.QueryResult<
  Types.AvailableBoatsQuery,
  Types.AvailableBoatsQueryVariables
>;
export function refetchAvailableBoatsQuery(variables?: Types.AvailableBoatsQueryVariables) {
  return { query: AvailableBoatsDocument, variables: variables };
}
export const AvailableBoatsAndLocationInfoDocument = gql`
  query availableBoatsAndLocationInfo(
    $locationId: ID
    $boatCategories: [ID!]
    $perPage: Int
    $page: Int
    $hasPhoto: String
    $dateFrom: ISO8601DateTime
    $dateTo: ISO8601DateTime
    $captain: String
    $url: String
    $withInfo: Boolean!
  ) {
    availableBoats(
      locationId: $locationId
      boatCategories: $boatCategories
      perPage: $perPage
      page: $page
      hasPhoto: $hasPhoto
      dateFrom: $dateFrom
      dateTo: $dateTo
      captain: $captain
      url: $url
    ) {
      ...AvailableBoatItems
    }
    locationInfo(url: $url, locationId: $locationId) {
      ...LocationInfo @include(if: $withInfo)
    }
    searchBoatsWithTopReview(url: $url, locationId: $locationId) {
      ...BoatTopReviewItems @include(if: $withInfo)
    }
  }
  ${AvailableBoatItemsFragmentDoc}
  ${LocationInfoFragmentDoc}
  ${BoatTopReviewItemsFragmentDoc}
`;

/**
 * __useAvailableBoatsAndLocationInfoQuery__
 *
 * To run a query within a React component, call `useAvailableBoatsAndLocationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBoatsAndLocationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBoatsAndLocationInfoQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      boatCategories: // value for 'boatCategories'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      hasPhoto: // value for 'hasPhoto'
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      captain: // value for 'captain'
 *      url: // value for 'url'
 *      withInfo: // value for 'withInfo'
 *   },
 * });
 */
export function useAvailableBoatsAndLocationInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.AvailableBoatsAndLocationInfoQuery,
    Types.AvailableBoatsAndLocationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.AvailableBoatsAndLocationInfoQuery, Types.AvailableBoatsAndLocationInfoQueryVariables>(
    AvailableBoatsAndLocationInfoDocument,
    options
  );
}
export function useAvailableBoatsAndLocationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.AvailableBoatsAndLocationInfoQuery,
    Types.AvailableBoatsAndLocationInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.AvailableBoatsAndLocationInfoQuery,
    Types.AvailableBoatsAndLocationInfoQueryVariables
  >(AvailableBoatsAndLocationInfoDocument, options);
}
export type AvailableBoatsAndLocationInfoQueryHookResult = ReturnType<typeof useAvailableBoatsAndLocationInfoQuery>;
export type AvailableBoatsAndLocationInfoLazyQueryHookResult = ReturnType<
  typeof useAvailableBoatsAndLocationInfoLazyQuery
>;
export type AvailableBoatsAndLocationInfoQueryResult = Apollo.QueryResult<
  Types.AvailableBoatsAndLocationInfoQuery,
  Types.AvailableBoatsAndLocationInfoQueryVariables
>;
export function refetchAvailableBoatsAndLocationInfoQuery(
  variables: Types.AvailableBoatsAndLocationInfoQueryVariables
) {
  return { query: AvailableBoatsAndLocationInfoDocument, variables: variables };
}
export const GetBoatDocument = gql`
  query getBoat($id: Int, $slug: String) {
    boat(id: $id, slug: $slug) {
      ...BoatFull
    }
  }
  ${BoatFullFragmentDoc}
`;

/**
 * __useGetBoatQuery__
 *
 * To run a query within a React component, call `useGetBoatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBoatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBoatQuery({
 *   variables: {
 *      id: // value for 'id'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetBoatQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.GetBoatQuery, Types.GetBoatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetBoatQuery, Types.GetBoatQueryVariables>(GetBoatDocument, options);
}
export function useGetBoatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBoatQuery, Types.GetBoatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetBoatQuery, Types.GetBoatQueryVariables>(GetBoatDocument, options);
}
export type GetBoatQueryHookResult = ReturnType<typeof useGetBoatQuery>;
export type GetBoatLazyQueryHookResult = ReturnType<typeof useGetBoatLazyQuery>;
export type GetBoatQueryResult = Apollo.QueryResult<Types.GetBoatQuery, Types.GetBoatQueryVariables>;
export function refetchGetBoatQuery(variables?: Types.GetBoatQueryVariables) {
  return { query: GetBoatDocument, variables: variables };
}
export const CharterDocument = gql`
  query charter($id: Int!) {
    charter(id: $id) {
      ...Charter
    }
  }
  ${CharterFragmentDoc}
`;

/**
 * __useCharterQuery__
 *
 * To run a query within a React component, call `useCharterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCharterQuery(baseOptions: Apollo.QueryHookOptions<Types.CharterQuery, Types.CharterQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CharterQuery, Types.CharterQueryVariables>(CharterDocument, options);
}
export function useCharterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CharterQuery, Types.CharterQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CharterQuery, Types.CharterQueryVariables>(CharterDocument, options);
}
export type CharterQueryHookResult = ReturnType<typeof useCharterQuery>;
export type CharterLazyQueryHookResult = ReturnType<typeof useCharterLazyQuery>;
export type CharterQueryResult = Apollo.QueryResult<Types.CharterQuery, Types.CharterQueryVariables>;
export function refetchCharterQuery(variables: Types.CharterQueryVariables) {
  return { query: CharterDocument, variables: variables };
}
export const ChartersDocument = gql`
  query charters($typeOrigin: CharterTypeOrigin) {
    charters(typeOrigin: $typeOrigin) {
      ...Charters
    }
  }
  ${ChartersFragmentDoc}
`;

/**
 * __useChartersQuery__
 *
 * To run a query within a React component, call `useChartersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartersQuery({
 *   variables: {
 *      typeOrigin: // value for 'typeOrigin'
 *   },
 * });
 */
export function useChartersQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ChartersQuery, Types.ChartersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ChartersQuery, Types.ChartersQueryVariables>(ChartersDocument, options);
}
export function useChartersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ChartersQuery, Types.ChartersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ChartersQuery, Types.ChartersQueryVariables>(ChartersDocument, options);
}
export type ChartersQueryHookResult = ReturnType<typeof useChartersQuery>;
export type ChartersLazyQueryHookResult = ReturnType<typeof useChartersLazyQuery>;
export type ChartersQueryResult = Apollo.QueryResult<Types.ChartersQuery, Types.ChartersQueryVariables>;
export function refetchChartersQuery(variables?: Types.ChartersQueryVariables) {
  return { query: ChartersDocument, variables: variables };
}
export const ChatDocument = gql`
  query chat($offerId: Int!) {
    chat(offerId: $offerId) {
      appId
      threadId
      userToken
    }
  }
`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useChatQuery(baseOptions: Apollo.QueryHookOptions<Types.ChatQuery, Types.ChatQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ChatQuery, Types.ChatQueryVariables>(ChatDocument, options);
}
export function useChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ChatQuery, Types.ChatQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ChatQuery, Types.ChatQueryVariables>(ChatDocument, options);
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<Types.ChatQuery, Types.ChatQueryVariables>;
export function refetchChatQuery(variables: Types.ChatQueryVariables) {
  return { query: ChatDocument, variables: variables };
}
export const CheckPromoCodeDocument = gql`
  query checkPromoCode($promoCode: String) {
    checkPromoCode(promoCode: $promoCode) {
      discountPercentage
      promoCode
      status
    }
  }
`;

/**
 * __useCheckPromoCodeQuery__
 *
 * To run a query within a React component, call `useCheckPromoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckPromoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPromoCodeQuery({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *   },
 * });
 */
export function useCheckPromoCodeQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.CheckPromoCodeQuery, Types.CheckPromoCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.CheckPromoCodeQuery, Types.CheckPromoCodeQueryVariables>(
    CheckPromoCodeDocument,
    options
  );
}
export function useCheckPromoCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.CheckPromoCodeQuery, Types.CheckPromoCodeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.CheckPromoCodeQuery, Types.CheckPromoCodeQueryVariables>(
    CheckPromoCodeDocument,
    options
  );
}
export type CheckPromoCodeQueryHookResult = ReturnType<typeof useCheckPromoCodeQuery>;
export type CheckPromoCodeLazyQueryHookResult = ReturnType<typeof useCheckPromoCodeLazyQuery>;
export type CheckPromoCodeQueryResult = Apollo.QueryResult<
  Types.CheckPromoCodeQuery,
  Types.CheckPromoCodeQueryVariables
>;
export function refetchCheckPromoCodeQuery(variables?: Types.CheckPromoCodeQueryVariables) {
  return { query: CheckPromoCodeDocument, variables: variables };
}
export const ForLandingPageDocument = gql`
  query forLandingPage($locationId: ID, $locationName: String, $limit: Int) {
    featuredBoatsForRent(locationId: $locationId, locationName: $locationName, limit: $limit) {
      ...BoatShort
    }
    rentBoats: searchBoats(
      locationId: $locationId
      locationName: $locationName
      page: 1
      perPage: $limit
      providedFor: 2
    ) {
      items {
        id
        slug
        name
        location {
          address
          country {
            name
          }
        }
        pictures {
          md
          w280
          w560
          w840
        }
        builtAt
        rating
        length
        persons
        cabins
        berths
        crewTotal
        boatProvidedFor
        exclusivePrice {
          currency
          pricePerHour
          pricePerDay
          pricePerWeek
        }
        salePrice {
          currency
          value
        }
      }
    }
    saleBoats: searchBoats(
      locationId: $locationId
      locationName: $locationName
      page: 1
      perPage: $limit
      providedFor: 1
    ) {
      items {
        ...BoatShort
      }
    }
  }
  ${BoatShortFragmentDoc}
`;

/**
 * __useForLandingPageQuery__
 *
 * To run a query within a React component, call `useForLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useForLandingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForLandingPageQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      locationName: // value for 'locationName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useForLandingPageQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ForLandingPageQuery, Types.ForLandingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ForLandingPageQuery, Types.ForLandingPageQueryVariables>(
    ForLandingPageDocument,
    options
  );
}
export function useForLandingPageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ForLandingPageQuery, Types.ForLandingPageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ForLandingPageQuery, Types.ForLandingPageQueryVariables>(
    ForLandingPageDocument,
    options
  );
}
export type ForLandingPageQueryHookResult = ReturnType<typeof useForLandingPageQuery>;
export type ForLandingPageLazyQueryHookResult = ReturnType<typeof useForLandingPageLazyQuery>;
export type ForLandingPageQueryResult = Apollo.QueryResult<
  Types.ForLandingPageQuery,
  Types.ForLandingPageQueryVariables
>;
export function refetchForLandingPageQuery(variables?: Types.ForLandingPageQueryVariables) {
  return { query: ForLandingPageDocument, variables: variables };
}
export const GetOfferByIdDocument = gql`
  query getOfferById($id: Int!) {
    offer(id: $id) {
      ...Offer
    }
  }
  ${OfferFragmentDoc}
`;

/**
 * __useGetOfferByIdQuery__
 *
 * To run a query within a React component, call `useGetOfferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferByIdQuery(
  baseOptions: Apollo.QueryHookOptions<Types.GetOfferByIdQuery, Types.GetOfferByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.GetOfferByIdQuery, Types.GetOfferByIdQueryVariables>(GetOfferByIdDocument, options);
}
export function useGetOfferByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.GetOfferByIdQuery, Types.GetOfferByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.GetOfferByIdQuery, Types.GetOfferByIdQueryVariables>(GetOfferByIdDocument, options);
}
export type GetOfferByIdQueryHookResult = ReturnType<typeof useGetOfferByIdQuery>;
export type GetOfferByIdLazyQueryHookResult = ReturnType<typeof useGetOfferByIdLazyQuery>;
export type GetOfferByIdQueryResult = Apollo.QueryResult<Types.GetOfferByIdQuery, Types.GetOfferByIdQueryVariables>;
export function refetchGetOfferByIdQuery(variables: Types.GetOfferByIdQueryVariables) {
  return { query: GetOfferByIdDocument, variables: variables };
}
export const LocationDocument = gql`
  query location($id: Int!) {
    location(id: $id) {
      address
      longitude
      latitude
      type
      country {
        name
      }
    }
  }
`;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.LocationQuery, Types.LocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LocationQuery, Types.LocationQueryVariables>(LocationDocument, options);
}
export function useLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LocationQuery, Types.LocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LocationQuery, Types.LocationQueryVariables>(LocationDocument, options);
}
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<Types.LocationQuery, Types.LocationQueryVariables>;
export function refetchLocationQuery(variables: Types.LocationQueryVariables) {
  return { query: LocationDocument, variables: variables };
}
export const LocationInfoDocument = gql`
  query locationInfo($url: String, $locationId: ID) {
    locationInfo(url: $url, locationId: $locationId) {
      ...LocationInfo
    }
    searchBoatsWithTopReview(url: $url) {
      ...BoatTopReviewItems
    }
  }
  ${LocationInfoFragmentDoc}
  ${BoatTopReviewItemsFragmentDoc}
`;

/**
 * __useLocationInfoQuery__
 *
 * To run a query within a React component, call `useLocationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationInfoQuery({
 *   variables: {
 *      url: // value for 'url'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.LocationInfoQuery, Types.LocationInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LocationInfoQuery, Types.LocationInfoQueryVariables>(LocationInfoDocument, options);
}
export function useLocationInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LocationInfoQuery, Types.LocationInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LocationInfoQuery, Types.LocationInfoQueryVariables>(LocationInfoDocument, options);
}
export type LocationInfoQueryHookResult = ReturnType<typeof useLocationInfoQuery>;
export type LocationInfoLazyQueryHookResult = ReturnType<typeof useLocationInfoLazyQuery>;
export type LocationInfoQueryResult = Apollo.QueryResult<Types.LocationInfoQuery, Types.LocationInfoQueryVariables>;
export function refetchLocationInfoQuery(variables?: Types.LocationInfoQueryVariables) {
  return { query: LocationInfoDocument, variables: variables };
}
export const LocationSuggestionsDocument = gql`
  query locationSuggestions($name: String!) {
    locationSuggestions(name: $name) {
      ...LocationSuggestion
    }
  }
  ${LocationSuggestionFragmentDoc}
`;

/**
 * __useLocationSuggestionsQuery__
 *
 * To run a query within a React component, call `useLocationSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationSuggestionsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useLocationSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<Types.LocationSuggestionsQuery, Types.LocationSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.LocationSuggestionsQuery, Types.LocationSuggestionsQueryVariables>(
    LocationSuggestionsDocument,
    options
  );
}
export function useLocationSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.LocationSuggestionsQuery, Types.LocationSuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.LocationSuggestionsQuery, Types.LocationSuggestionsQueryVariables>(
    LocationSuggestionsDocument,
    options
  );
}
export type LocationSuggestionsQueryHookResult = ReturnType<typeof useLocationSuggestionsQuery>;
export type LocationSuggestionsLazyQueryHookResult = ReturnType<typeof useLocationSuggestionsLazyQuery>;
export type LocationSuggestionsQueryResult = Apollo.QueryResult<
  Types.LocationSuggestionsQuery,
  Types.LocationSuggestionsQueryVariables
>;
export function refetchLocationSuggestionsQuery(variables: Types.LocationSuggestionsQueryVariables) {
  return { query: LocationSuggestionsDocument, variables: variables };
}
export const OffersDocument = gql`
  query offers($charterId: Int!) {
    offers(charterId: $charterId) {
      id
      boat {
        ...BoatShort
      }
      price {
        amount
        currency
      }
      priceWithoutDiscount {
        amount
        currency
      }
      payments {
        id
        status
        type
        amount {
          amount
          currency
        }
      }
      firstPaymentAmount
      payTillOn
      prepayPercent
      secondPaymentAmount
      secondPaymentPercent
      startAt
      endAt
      isPreliminary
      isUserInterest
      status
    }
  }
  ${BoatShortFragmentDoc}
`;

/**
 * __useOffersQuery__
 *
 * To run a query within a React component, call `useOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersQuery({
 *   variables: {
 *      charterId: // value for 'charterId'
 *   },
 * });
 */
export function useOffersQuery(baseOptions: Apollo.QueryHookOptions<Types.OffersQuery, Types.OffersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, options);
}
export function useOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.OffersQuery, Types.OffersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.OffersQuery, Types.OffersQueryVariables>(OffersDocument, options);
}
export type OffersQueryHookResult = ReturnType<typeof useOffersQuery>;
export type OffersLazyQueryHookResult = ReturnType<typeof useOffersLazyQuery>;
export type OffersQueryResult = Apollo.QueryResult<Types.OffersQuery, Types.OffersQueryVariables>;
export function refetchOffersQuery(variables: Types.OffersQueryVariables) {
  return { query: OffersDocument, variables: variables };
}
export const OffersLinkDocument = gql`
  query offersLink($days: Int!) {
    offersLink(days: $days)
  }
`;

/**
 * __useOffersLinkQuery__
 *
 * To run a query within a React component, call `useOffersLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useOffersLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOffersLinkQuery({
 *   variables: {
 *      days: // value for 'days'
 *   },
 * });
 */
export function useOffersLinkQuery(
  baseOptions: Apollo.QueryHookOptions<Types.OffersLinkQuery, Types.OffersLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.OffersLinkQuery, Types.OffersLinkQueryVariables>(OffersLinkDocument, options);
}
export function useOffersLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.OffersLinkQuery, Types.OffersLinkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.OffersLinkQuery, Types.OffersLinkQueryVariables>(OffersLinkDocument, options);
}
export type OffersLinkQueryHookResult = ReturnType<typeof useOffersLinkQuery>;
export type OffersLinkLazyQueryHookResult = ReturnType<typeof useOffersLinkLazyQuery>;
export type OffersLinkQueryResult = Apollo.QueryResult<Types.OffersLinkQuery, Types.OffersLinkQueryVariables>;
export function refetchOffersLinkQuery(variables: Types.OffersLinkQueryVariables) {
  return { query: OffersLinkDocument, variables: variables };
}
export const PartnershipDocument = gql`
  query partnership {
    partnership {
      partner {
        id
        name
        email
        contacts
        phone
        referralTag
      }
      promoCodes {
        id
        promoCode
        discountPercentage
        quantityForUse
        quantityUsed
        expiredAt
        status
      }
      accounts {
        id
        isSeniorAccount
        isPartnerAccount
        email
        fullName
        roles
      }
    }
  }
`;

/**
 * __usePartnershipQuery__
 *
 * To run a query within a React component, call `usePartnershipQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnershipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnershipQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnershipQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.PartnershipQuery, Types.PartnershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PartnershipQuery, Types.PartnershipQueryVariables>(PartnershipDocument, options);
}
export function usePartnershipLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PartnershipQuery, Types.PartnershipQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PartnershipQuery, Types.PartnershipQueryVariables>(PartnershipDocument, options);
}
export type PartnershipQueryHookResult = ReturnType<typeof usePartnershipQuery>;
export type PartnershipLazyQueryHookResult = ReturnType<typeof usePartnershipLazyQuery>;
export type PartnershipQueryResult = Apollo.QueryResult<Types.PartnershipQuery, Types.PartnershipQueryVariables>;
export function refetchPartnershipQuery(variables?: Types.PartnershipQueryVariables) {
  return { query: PartnershipDocument, variables: variables };
}
export const PaymentDocument = gql`
  query payment($id: Int!) {
    payment(id: $id) {
      id
      status
      amount {
        amount
        currency
      }
      token
      redirectUrl
      paymentMethod {
        id
        type
      }
      offer {
        id
        charter {
          id
          locations {
            id
            location {
              address
            }
            name
          }
        }
        price {
          amount
          currency
        }
      }
    }
  }
`;

/**
 * __usePaymentQuery__
 *
 * To run a query within a React component, call `usePaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePaymentQuery(baseOptions: Apollo.QueryHookOptions<Types.PaymentQuery, Types.PaymentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.PaymentQuery, Types.PaymentQueryVariables>(PaymentDocument, options);
}
export function usePaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.PaymentQuery, Types.PaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.PaymentQuery, Types.PaymentQueryVariables>(PaymentDocument, options);
}
export type PaymentQueryHookResult = ReturnType<typeof usePaymentQuery>;
export type PaymentLazyQueryHookResult = ReturnType<typeof usePaymentLazyQuery>;
export type PaymentQueryResult = Apollo.QueryResult<Types.PaymentQuery, Types.PaymentQueryVariables>;
export function refetchPaymentQuery(variables: Types.PaymentQueryVariables) {
  return { query: PaymentDocument, variables: variables };
}
export const ProfileDocument = gql`
  query profile {
    profile {
      accessAdmin
      id
      email
      active
      isCentralAgent
      isAdmin
      isPaidSubscription
      isSeniorAccount
      isPartnerAccount
    }
  }
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfileQuery, Types.ProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.ProfileQuery, Types.ProfileQueryVariables>(ProfileDocument, options);
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<Types.ProfileQuery, Types.ProfileQueryVariables>;
export function refetchProfileQuery(variables?: Types.ProfileQueryVariables) {
  return { query: ProfileDocument, variables: variables };
}
export const SearchBoatCategoriesDocument = gql`
  query searchBoatCategories($location: String) {
    searchBoatCategories(location: $location) {
      id
      name
      picture {
        w85
        w170
        w255
        w340
        w154
        w308
        w462
        w616
      }
      price {
        amount
        currency
      }
    }
  }
`;

/**
 * __useSearchBoatCategoriesQuery__
 *
 * To run a query within a React component, call `useSearchBoatCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoatCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoatCategoriesQuery({
 *   variables: {
 *      location: // value for 'location'
 *   },
 * });
 */
export function useSearchBoatCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SearchBoatCategoriesQuery, Types.SearchBoatCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchBoatCategoriesQuery, Types.SearchBoatCategoriesQueryVariables>(
    SearchBoatCategoriesDocument,
    options
  );
}
export function useSearchBoatCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBoatCategoriesQuery, Types.SearchBoatCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchBoatCategoriesQuery, Types.SearchBoatCategoriesQueryVariables>(
    SearchBoatCategoriesDocument,
    options
  );
}
export type SearchBoatCategoriesQueryHookResult = ReturnType<typeof useSearchBoatCategoriesQuery>;
export type SearchBoatCategoriesLazyQueryHookResult = ReturnType<typeof useSearchBoatCategoriesLazyQuery>;
export type SearchBoatCategoriesQueryResult = Apollo.QueryResult<
  Types.SearchBoatCategoriesQuery,
  Types.SearchBoatCategoriesQueryVariables
>;
export function refetchSearchBoatCategoriesQuery(variables?: Types.SearchBoatCategoriesQueryVariables) {
  return { query: SearchBoatCategoriesDocument, variables: variables };
}
export const SearchBoatsDocument = gql`
  query searchBoats(
    $boatCategories: [ID!]
    $builtAt: IntegerRange
    $length: FloatRange
    $location: String
    $locationName: String
    $locationId: ID
    $page: Int
    $perPage: Int
    $condition: Int
    $providedFor: Int
  ) {
    searchBoats(
      boatCategories: $boatCategories
      builtAt: $builtAt
      length: $length
      location: $location
      locationName: $locationName
      locationId: $locationId
      page: $page
      perPage: $perPage
      condition: $condition
      providedFor: $providedFor
    ) {
      items {
        ...BoatShort
      }
      meta {
        totalCount
      }
    }
  }
  ${BoatShortFragmentDoc}
`;

/**
 * __useSearchBoatsQuery__
 *
 * To run a query within a React component, call `useSearchBoatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBoatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBoatsQuery({
 *   variables: {
 *      boatCategories: // value for 'boatCategories'
 *      builtAt: // value for 'builtAt'
 *      length: // value for 'length'
 *      location: // value for 'location'
 *      locationName: // value for 'locationName'
 *      locationId: // value for 'locationId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      condition: // value for 'condition'
 *      providedFor: // value for 'providedFor'
 *   },
 * });
 */
export function useSearchBoatsQuery(
  baseOptions?: Apollo.QueryHookOptions<Types.SearchBoatsQuery, Types.SearchBoatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchBoatsQuery, Types.SearchBoatsQueryVariables>(SearchBoatsDocument, options);
}
export function useSearchBoatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchBoatsQuery, Types.SearchBoatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchBoatsQuery, Types.SearchBoatsQueryVariables>(SearchBoatsDocument, options);
}
export type SearchBoatsQueryHookResult = ReturnType<typeof useSearchBoatsQuery>;
export type SearchBoatsLazyQueryHookResult = ReturnType<typeof useSearchBoatsLazyQuery>;
export type SearchBoatsQueryResult = Apollo.QueryResult<Types.SearchBoatsQuery, Types.SearchBoatsQueryVariables>;
export function refetchSearchBoatsQuery(variables?: Types.SearchBoatsQueryVariables) {
  return { query: SearchBoatsDocument, variables: variables };
}
export const SearchLocationDocument = gql`
  query searchLocation($name: String!) {
    locationSuggestions(name: $name, limit: 1) {
      address
      allBoatsCount
      boatCategoriesIds
      country {
        id
        name
      }
      id
      near {
        id
        address
      }
    }
  }
`;

/**
 * __useSearchLocationQuery__
 *
 * To run a query within a React component, call `useSearchLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLocationQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSearchLocationQuery(
  baseOptions: Apollo.QueryHookOptions<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>(
    SearchLocationDocument,
    options
  );
}
export function useSearchLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SearchLocationQuery, Types.SearchLocationQueryVariables>(
    SearchLocationDocument,
    options
  );
}
export type SearchLocationQueryHookResult = ReturnType<typeof useSearchLocationQuery>;
export type SearchLocationLazyQueryHookResult = ReturnType<typeof useSearchLocationLazyQuery>;
export type SearchLocationQueryResult = Apollo.QueryResult<
  Types.SearchLocationQuery,
  Types.SearchLocationQueryVariables
>;
export function refetchSearchLocationQuery(variables: Types.SearchLocationQueryVariables) {
  return { query: SearchLocationDocument, variables: variables };
}
export const SendPasswordResetEmailDocument = gql`
  query sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email)
  }
`;

/**
 * __useSendPasswordResetEmailQuery__
 *
 * To run a query within a React component, call `useSendPasswordResetEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPasswordResetEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetEmailQuery(
  baseOptions: Apollo.QueryHookOptions<Types.SendPasswordResetEmailQuery, Types.SendPasswordResetEmailQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.SendPasswordResetEmailQuery, Types.SendPasswordResetEmailQueryVariables>(
    SendPasswordResetEmailDocument,
    options
  );
}
export function useSendPasswordResetEmailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.SendPasswordResetEmailQuery,
    Types.SendPasswordResetEmailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.SendPasswordResetEmailQuery, Types.SendPasswordResetEmailQueryVariables>(
    SendPasswordResetEmailDocument,
    options
  );
}
export type SendPasswordResetEmailQueryHookResult = ReturnType<typeof useSendPasswordResetEmailQuery>;
export type SendPasswordResetEmailLazyQueryHookResult = ReturnType<typeof useSendPasswordResetEmailLazyQuery>;
export type SendPasswordResetEmailQueryResult = Apollo.QueryResult<
  Types.SendPasswordResetEmailQuery,
  Types.SendPasswordResetEmailQueryVariables
>;
export function refetchSendPasswordResetEmailQuery(variables: Types.SendPasswordResetEmailQueryVariables) {
  return { query: SendPasswordResetEmailDocument, variables: variables };
}
export const StripeBillingPortalDocument = gql`
  query stripeBillingPortal($returnUrl: String!) {
    stripeBillingPortal(returnUrl: $returnUrl) {
      redirectUrl
    }
  }
`;

/**
 * __useStripeBillingPortalQuery__
 *
 * To run a query within a React component, call `useStripeBillingPortalQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeBillingPortalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeBillingPortalQuery({
 *   variables: {
 *      returnUrl: // value for 'returnUrl'
 *   },
 * });
 */
export function useStripeBillingPortalQuery(
  baseOptions: Apollo.QueryHookOptions<Types.StripeBillingPortalQuery, Types.StripeBillingPortalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.StripeBillingPortalQuery, Types.StripeBillingPortalQueryVariables>(
    StripeBillingPortalDocument,
    options
  );
}
export function useStripeBillingPortalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Types.StripeBillingPortalQuery, Types.StripeBillingPortalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Types.StripeBillingPortalQuery, Types.StripeBillingPortalQueryVariables>(
    StripeBillingPortalDocument,
    options
  );
}
export type StripeBillingPortalQueryHookResult = ReturnType<typeof useStripeBillingPortalQuery>;
export type StripeBillingPortalLazyQueryHookResult = ReturnType<typeof useStripeBillingPortalLazyQuery>;
export type StripeBillingPortalQueryResult = Apollo.QueryResult<
  Types.StripeBillingPortalQuery,
  Types.StripeBillingPortalQueryVariables
>;
export function refetchStripeBillingPortalQuery(variables: Types.StripeBillingPortalQueryVariables) {
  return { query: StripeBillingPortalDocument, variables: variables };
}
export const CharterOffersDocument = gql`
  subscription charterOffers($charterId: Int!) {
    charterOffers(charterId: $charterId) {
      id
      boat {
        ...BoatShort
      }
      price {
        amount
        currency
      }
      priceWithoutDiscount {
        amount
        currency
      }
      payments {
        id
        status
        type
        amount {
          amount
          currency
        }
      }
      firstPaymentAmount
      payTillOn
      prepayPercent
      secondPaymentAmount
      secondPaymentPercent
      startAt
      endAt
      isPreliminary
      isUserInterest
      status
    }
  }
  ${BoatShortFragmentDoc}
`;

/**
 * __useCharterOffersSubscription__
 *
 * To run a query within a React component, call `useCharterOffersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCharterOffersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharterOffersSubscription({
 *   variables: {
 *      charterId: // value for 'charterId'
 *   },
 * });
 */
export function useCharterOffersSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<Types.CharterOffersSubscription, Types.CharterOffersSubscriptionVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<Types.CharterOffersSubscription, Types.CharterOffersSubscriptionVariables>(
    CharterOffersDocument,
    options
  );
}
export type CharterOffersSubscriptionHookResult = ReturnType<typeof useCharterOffersSubscription>;
export type CharterOffersSubscriptionResult = Apollo.SubscriptionResult<Types.CharterOffersSubscription>;
export const CheckPaidSalePlanDocument = gql`
  subscription checkPaidSalePlan($sessionId: String!) {
    checkPaidSalePlan(sessionId: $sessionId) {
      success
    }
  }
`;

/**
 * __useCheckPaidSalePlanSubscription__
 *
 * To run a query within a React component, call `useCheckPaidSalePlanSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCheckPaidSalePlanSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckPaidSalePlanSubscription({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCheckPaidSalePlanSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Types.CheckPaidSalePlanSubscription,
    Types.CheckPaidSalePlanSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<Types.CheckPaidSalePlanSubscription, Types.CheckPaidSalePlanSubscriptionVariables>(
    CheckPaidSalePlanDocument,
    options
  );
}
export type CheckPaidSalePlanSubscriptionHookResult = ReturnType<typeof useCheckPaidSalePlanSubscription>;
export type CheckPaidSalePlanSubscriptionResult = Apollo.SubscriptionResult<Types.CheckPaidSalePlanSubscription>;
