import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import cn from 'classnames';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { Button, Input, message, notification, Select } from '$ui/index';

import {
  useCurrentUserLocalQuery,
  useGetOfferByIdQuery,
  usePaymentQuery,
  useUpdatePaymentMutation,
  useAddPlatronPaymentMutation,
} from '$graphql/hooks';
import { routes } from '$router/index';
import { PaymentViewParams } from '$router/types';
import useGeneratePath from '$hooks/useGeneratePath';
import { PaymentStatus } from '$graphql/types';
import { formatCurrency } from '$utils/formatCurrency';
import { IconAccount } from '$components/index';

import { CARD_ELEMENT_OPTIONS } from '$pages/PaymentPage/data';
import { Boat, Dates, Price, Payment, PaymentSystems, FormPlatron } from './components/index';
import commonStyles from '$assets/styles/Common.module.less';
import styles from './PaymentPage.module.less';

const StripeSection = () => {
  const { generatePath } = useGeneratePath();
  const params = useParams<PaymentViewParams>();
  const paymentId = Number(params.paymentId);
  const sendDataToGTM = useGTMDispatch();

  const { data: user } = useCurrentUserLocalQuery();

  const [processing, setProcessing] = useState(false);
  const { data: paymentData, loading } = usePaymentQuery({ variables: { id: paymentId } });
  const [updatePayment] = useUpdatePaymentMutation({ errorPolicy: 'all' });
  const [addPlatronPayment] = useAddPlatronPaymentMutation({ errorPolicy: 'all' });

  const [currentBillingSystem, setCurrentBillingSystem] = useState<'stripe' | 'platron'>('stripe');
  const [platronAdditionStatus, setPlatronAdditionStatus] = useState<'none' | 'loading' | 'loaded' | 'error'>('none');
  const [redirectUrlPlatron, setRedirectUrlPlatron] = useState<string | null | undefined>();

  const { data: offerData } = useGetOfferByIdQuery({ variables: { id: paymentData?.payment?.offer.id || 1 } });
  const offer = offerData?.offer;

  const stripe = useStripe();
  const elements = useElements();

  const payment = paymentData?.payment;

  const currencyList = [
    {
      value: payment?.offer.price?.currency === 'EUR' ? 'eur' : 'usd',
      label: payment?.offer.price?.currency === 'EUR' ? 'Euro' : 'Dollar',
    },
    { value: 'rub', label: 'Ruble' },
  ];

  if (!stripe || !elements || !paymentData) {
    return null;
  }

  const handleChangeCurrentCurrency = (value: string) => {
    const redirectUrlPlatron = offer?.payments.find(
      (item) => item.paymentMethod?.type === 'PaymentMethod::Platron' && item.redirectUrl
    )?.redirectUrl;

    if (value === 'rub' && redirectUrlPlatron) {
      setPlatronAdditionStatus('loaded');
    } else if (value === 'rub' && !redirectUrlPlatron && platronAdditionStatus === 'none') {
      setPlatronAdditionStatus('loading');

      if (offer && offer.id && offer.price?.amount) {
        addPlatronPayment({
          variables: {
            offerId: `${offer?.id}`,
            amount: offer.price.amount,
            currency: offer.price.currency || 'usd',
          },
        })
          .then((result) => {
            if (result.data?.addPlatronPayment?.redirectUrl) {
              setRedirectUrlPlatron(result.data.addPlatronPayment.redirectUrl);
              setPlatronAdditionStatus('loaded');
            } else {
              setPlatronAdditionStatus('error');
            }
          })
          .catch(() => {
            setPlatronAdditionStatus('error');
          });
      }
    }

    setCurrentBillingSystem(value === 'rub' ? 'platron' : 'stripe');
  };

  const handleSubmit = async () => {
    const elementOnPage = elements.getElement(CardElement);

    if (!stripe || !elements || !paymentData?.payment?.token || !elementOnPage) {
      return null;
    }

    setProcessing(true);

    const result = await stripe.confirmCardPayment(paymentData?.payment?.token, {
      payment_method: {
        card: elementOnPage,
      },
      setup_future_usage: 'off_session',
    });

    if (result.error) {
      message.error(result.error.message);
    } else if (result.paymentIntent) {
      if (result.paymentIntent.status === 'requires_capture') {
        notification.success({
          message: 'Success',
          key: 'requires_capture',
          placement: 'bottomRight',
          duration: 5,
        });

        updatePayment({
          variables: {
            id: paymentId + '',
            status: 'hold',
          },
        }).then(() => {
          if (paymentData?.payment?.offer.id) {
            sendDataToGTM({ event: 'submit_ga_payment_page_form', value: paymentData.payment.offer.id });

            setTimeout(() => {
              /* TODO 2021 переделать на React Router */
              window.location.href = generatePath(routes.BOOKING_VIEW, {
                offerId: `${paymentData?.payment?.offer.id}`,
              });
            }, 1000);
          }
        });
      }
    }

    setProcessing(false);
  };

  return (
    <div className={cn(commonStyles.container, commonStyles.containerHigh, styles.container)}>
      <div className={styles.back}>
        <Link
          to={generatePath(routes.CHARTER_VIEW, {
            charterId: `${paymentData?.payment?.offer?.charter?.id}`,
          })}
        >
          <IconAccount name="arrowLeft" /> Confirm and pay
        </Link>
      </div>
      <div className={styles.wrapperInfoForm}>
        <div className={styles.info}>
          <Boat boat={offerData?.offer?.boat} offer={offerData?.offer} />
          <Dates offer={offer} className={styles.options} />
          {offer && <Price offer={offer} className={styles.price} />}
        </div>
        <div className={styles.form}>
          <div className={styles.alert}>
            {/*<div className={styles.title}>Free cancellation.</div>*/}
            <div className={styles.text}>
              <IconAccount name="clock" /> Your trip starts soon. Book now before it sells out.
            </div>
          </div>

          <div className={styles.contactForm}>
            <div className={styles.title}>Contact information</div>
            <div className={styles.wrapperInput}>
              <Input className={styles.input} placeholder="Full name" size="large" allowClear />
            </div>
            <div className={styles.wrapperInput}>
              <Input
                className={styles.input}
                placeholder="Contact phone number"
                defaultValue={offer?.charter?.phones && offer.charter.phones.length > 0 ? offer.charter.phones[0] : ''}
                size="large"
                allowClear
              />
            </div>
            {user?.currentUser && (
              <div className={styles.wrapperInput}>
                <Input
                  className={styles.input}
                  placeholder="E-mail"
                  defaultValue={user.currentUser.email}
                  size="large"
                  allowClear
                  disabled
                />
              </div>
            )}
          </div>

          <Payment offer={offer} className={styles.payment} isOnePayment={currentBillingSystem === 'platron'} />

          <div className={styles.selectorCurrency}>
            <div className={styles.label}>Currency:</div>
            <Select
              defaultValue={payment?.offer.price?.currency.toLowerCase()}
              options={currencyList}
              onChange={handleChangeCurrentCurrency}
              className={styles.input}
            />
          </div>

          {currentBillingSystem === 'stripe' && (
            <>
              <div className={styles.billingInfo}>
                <div className={styles.title}>Billing info</div>
                <PaymentSystems />
              </div>

              {payment && payment.status === PaymentStatus.Created && (
                <div className={styles.billingForm}>
                  <div className={styles.form}>
                    <CardElement options={CARD_ELEMENT_OPTIONS} />
                  </div>
                  <div className={styles.freezeInfo}>
                    We will hold amount on the card and will withdraw it only after owner&apos;s confirmation.
                  </div>
                  <div className={styles.wrapperButton}>
                    <Button
                      className={cn(styles.buttonSubmit, 'ga_payment_page_send_button')}
                      disabled={!stripe || loading}
                      loading={processing}
                      onClick={handleSubmit}
                    >
                      Pay {formatCurrency(offer?.firstPaymentAmount, offer?.price?.currency)}
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}

          {currentBillingSystem === 'platron' && offer && offer.price?.amount && (
            <FormPlatron
              status={platronAdditionStatus}
              price={offer.price?.amount}
              currency={offer.price?.currency || 'usd'}
              redirectUrl={
                redirectUrlPlatron ||
                offer?.payments.find(
                  (item) => item.paymentMethod?.type === 'PaymentMethod::Platron' && item.redirectUrl
                )?.redirectUrl
              }
            />
          )}
          <div className={styles.secureInfo}>
            <IconAccount name="lock" /> Payments are secure and encrypted
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeSection;
