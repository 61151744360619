/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any };
  /** Represents untyped JSON */
  JSON: { input: any; output: any };
};

export type Account = {
  authyId?: Maybe<Scalars['Int']['output']>;
  cellphone?: Maybe<Scalars['String']['output']>;
  centralAgentRequest?: Maybe<Scalars['Boolean']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isCentralAgent: Scalars['Boolean']['output'];
  isPaidSubscription?: Maybe<Scalars['Boolean']['output']>;
  isPartnerAccount?: Maybe<Scalars['Boolean']['output']>;
  isSeniorAccount?: Maybe<Scalars['Boolean']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  token: Scalars['String']['output'];
};

export type AvailableBoat = {
  berths?: Maybe<Scalars['Int']['output']>;
  boatAdditionalInfo?: Maybe<BoatAdditionalInfo>;
  boatCategory?: Maybe<BoatCategory>;
  boatCondition?: Maybe<Scalars['String']['output']>;
  boatConditionAsInt?: Maybe<Scalars['Int']['output']>;
  boatProducts?: Maybe<Array<BoatProduct>>;
  boatProvidedFor?: Maybe<Scalars['String']['output']>;
  boatReviews?: Maybe<Array<BoatReview>>;
  brand?: Maybe<Scalars['String']['output']>;
  builtAt?: Maybe<Scalars['Int']['output']>;
  cabins?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  captain?: Maybe<Scalars['Int']['output']>;
  crewTotal?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donut?: Maybe<Scalars['Int']['output']>;
  engineHp?: Maybe<Scalars['Int']['output']>;
  engines?: Maybe<Scalars['Int']['output']>;
  exclusivePrice?: Maybe<BoatExclusivePrice>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  fishingGear?: Maybe<Scalars['Int']['output']>;
  fuelCapacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<Scalars['String']['output']>;
  jetSky?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Location>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paddleBoards?: Maybe<Scalars['Int']['output']>;
  persons?: Maybe<Scalars['Int']['output']>;
  pictures: Array<Picture>;
  prices: Price;
  rating?: Maybe<Scalars['Float']['output']>;
  ratingByReviews?: Maybe<Scalars['Float']['output']>;
  salePrice?: Maybe<BoatSalePrice>;
  similarBoatsInLocation?: Maybe<Array<Boat>>;
  slug?: Maybe<Scalars['String']['output']>;
  slugs?: Maybe<Slugs>;
  snorkingGear?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  wakeboard?: Maybe<Scalars['Int']['output']>;
};

export type AvailableBoatItems = {
  items: Array<AvailableBoat>;
  meta: MetaSeoQueries;
};

export type AvailableUrlCategories = {
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type BillingPortal = {
  accountId?: Maybe<Scalars['ID']['output']>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
};

export type Boat = {
  berths?: Maybe<Scalars['Int']['output']>;
  boatAdditionalInfo?: Maybe<BoatAdditionalInfo>;
  boatCategory?: Maybe<BoatCategory>;
  boatCondition?: Maybe<Scalars['String']['output']>;
  boatConditionAsInt?: Maybe<Scalars['Int']['output']>;
  boatProducts?: Maybe<Array<BoatProduct>>;
  boatProvidedFor?: Maybe<Scalars['String']['output']>;
  boatReviews?: Maybe<Array<BoatReview>>;
  brand?: Maybe<Scalars['String']['output']>;
  builtAt?: Maybe<Scalars['Int']['output']>;
  cabins?: Maybe<Scalars['Int']['output']>;
  cancellationPolicy?: Maybe<Scalars['String']['output']>;
  capacity?: Maybe<Scalars['Int']['output']>;
  captain?: Maybe<Scalars['Int']['output']>;
  crewTotal?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  donut?: Maybe<Scalars['Int']['output']>;
  engineHp?: Maybe<Scalars['Int']['output']>;
  engines?: Maybe<Scalars['Int']['output']>;
  exclusivePrice?: Maybe<BoatExclusivePrice>;
  features?: Maybe<Array<Scalars['String']['output']>>;
  fishingGear?: Maybe<Scalars['Int']['output']>;
  fuelCapacity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  img?: Maybe<Scalars['String']['output']>;
  jetSky?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  location?: Maybe<Location>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  paddleBoards?: Maybe<Scalars['Int']['output']>;
  persons?: Maybe<Scalars['Int']['output']>;
  pictures: Array<Picture>;
  prices: Price;
  rating?: Maybe<Scalars['Float']['output']>;
  ratingByReviews?: Maybe<Scalars['Float']['output']>;
  salePrice?: Maybe<BoatSalePrice>;
  similarBoatsInLocation?: Maybe<Array<Boat>>;
  slug?: Maybe<Scalars['String']['output']>;
  slugs?: Maybe<Slugs>;
  snorkingGear?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  wakeboard?: Maybe<Scalars['Int']['output']>;
};

export type BoatAdditionalInfo = {
  accommodationAndCrew?: Maybe<Scalars['JSON']['output']>;
  amenities?: Maybe<Scalars['JSON']['output']>;
  dimensions?: Maybe<Scalars['JSON']['output']>;
  equipment?: Maybe<Scalars['JSON']['output']>;
  generalInformation?: Maybe<Scalars['JSON']['output']>;
  performance?: Maybe<Scalars['JSON']['output']>;
  rentPrice?: Maybe<Scalars['JSON']['output']>;
  tendersAndToys?: Maybe<Scalars['JSON']['output']>;
};

export type BoatCategory = {
  default: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<BoatCategoryPicture>;
  position?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Money>;
};

export type BoatCategoryPicture = {
  w85?: Maybe<Scalars['String']['output']>;
  w154?: Maybe<Scalars['String']['output']>;
  w170?: Maybe<Scalars['String']['output']>;
  w255?: Maybe<Scalars['String']['output']>;
  w308?: Maybe<Scalars['String']['output']>;
  w340?: Maybe<Scalars['String']['output']>;
  w462?: Maybe<Scalars['String']['output']>;
  w616?: Maybe<Scalars['String']['output']>;
  x1?: Maybe<Scalars['String']['output']>;
  x2?: Maybe<Scalars['String']['output']>;
  x4?: Maybe<Scalars['String']['output']>;
};

export type BoatExclusivePrice = {
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  pricePerDay?: Maybe<Scalars['Float']['output']>;
  pricePerHour?: Maybe<Scalars['Float']['output']>;
  pricePerWeek?: Maybe<Scalars['Float']['output']>;
};

export type BoatItems = {
  items: Array<Boat>;
  meta: Meta;
};

export type BoatNearLocationFilter = {
  countryId?: InputMaybe<Scalars['ID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['ID']['input'];
};

export type BoatProduct = {
  boatId: Scalars['ID']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  obligatory: Scalars['Boolean']['output'];
  payableInBase: Scalars['Boolean']['output'];
  price: Scalars['Float']['output'];
  priceEur: Scalars['String']['output'];
  productName: Scalars['String']['output'];
  productType: Scalars['String']['output'];
  unit: Scalars['String']['output'];
};

export type BoatReview = {
  account: Account;
  accountId: Scalars['ID']['output'];
  boatId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deContent: Scalars['String']['output'];
  enContent: Scalars['String']['output'];
  frContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rating: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type BoatReviewItems = {
  items: Array<BoatReview>;
  meta: MetaSeoQueries;
};

export type BoatSalePrice = {
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type BoatTopReview = {
  account: ProtectedAccount;
  accountId: Scalars['ID']['output'];
  boat: Boat;
  boatId: Scalars['ID']['output'];
  createdAt: Scalars['ISO8601DateTime']['output'];
  deContent: Scalars['String']['output'];
  enContent: Scalars['String']['output'];
  frContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rating: Scalars['Float']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type BoatTopReviewItems = {
  items: Array<BoatTopReview>;
  meta: MetaBoatTopReviews;
};

export type CentralAgent = {
  accountPhone?: Maybe<Scalars['String']['output']>;
  companyPhone?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  joinedDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Charter = {
  boat?: Maybe<Boat>;
  boatCategories: Array<BoatCategory>;
  captain: Scalars['Boolean']['output'];
  charterPeriodTime?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  createdBy?: Maybe<CharterCreatedByEnum>;
  drivingEquipment?: Maybe<Scalars['Int']['output']>;
  endsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  flexibleDates: Scalars['Boolean']['output'];
  guests?: Maybe<Scalars['Int']['output']>;
  hoursFrom?: Maybe<Scalars['Int']['output']>;
  hoursTo?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isCreatedPartner?: Maybe<Scalars['Boolean']['output']>;
  jetSky?: Maybe<Scalars['Int']['output']>;
  locations: Array<CharterLocation>;
  offers?: Maybe<Array<Offer>>;
  partner?: Maybe<Partner>;
  partnerId?: Maybe<Scalars['Int']['output']>;
  phones: Array<Scalars['String']['output']>;
  price?: Maybe<Money>;
  priceMax?: Maybe<Money>;
  priceMin?: Maybe<Money>;
  promoCode?: Maybe<PromoCode>;
  promoCodeOriginal?: Maybe<Scalars['String']['output']>;
  startsAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  startsAtExactHour?: Maybe<Scalars['String']['output']>;
  status: CharterStatus;
  transferNeeded: Scalars['Boolean']['output'];
  utmReferralLink?: Maybe<Scalars['String']['output']>;
  watersports: Scalars['Boolean']['output'];
};

export enum CharterCreatedByEnum {
  /** created by promo code */
  PromoCode = 'promo_code',
  /** created by referral link */
  ReferralLink = 'referral_link',
}

export type CharterLocation = {
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CharterLocationArgument = {
  location?: InputMaybe<LocationArgument>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum CharterStatus {
  /** Accepted */
  Accepted = 'accepted',
  /** Canceled */
  Canceled = 'canceled',
  /** Done */
  Done = 'done',
  /** Created */
  Pending = 'pending',
}

export enum CharterTypeOrigin {
  /** all charters allowed role */
  All = 'all',
  /** own charters partner */
  Own = 'own',
  /** all charters of partner */
  Partner = 'partner',
  /** all charters by promo code */
  PromoCode = 'promo_code',
  /** charters by referral link */
  ReferralLink = 'referral_link',
}

export type Chat = {
  appId: Scalars['Int']['output'];
  threadId: Scalars['String']['output'];
  userToken: Scalars['String']['output'];
};

export type Country = {
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  long?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  sailingArea: SailingArea;
  short?: Maybe<Scalars['String']['output']>;
  worldRegion: WorldRegion;
};

export type CurrentUser = {
  accessAdmin: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  isAdmin: Scalars['Boolean']['output'];
  isCentralAgent: Scalars['Boolean']['output'];
  isLoggedIn: Scalars['Boolean']['output'];
  isPaidSubscription: Scalars['Boolean']['output'];
  isPartnerAccount: Scalars['Boolean']['output'];
  isSeniorAccount: Scalars['Boolean']['output'];
};

export type Faq = {
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deAnswer: Scalars['String']['output'];
  deQuestion: Scalars['String']['output'];
  enAnswer: Scalars['String']['output'];
  enQuestion: Scalars['String']['output'];
  frAnswer: Scalars['String']['output'];
  frQuestion: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  visible: Scalars['Boolean']['output'];
};

export type FloatRange = {
  from: Scalars['Float']['input'];
  to: Scalars['Float']['input'];
};

export type FloatRanges = {
  from: Scalars['Float']['output'];
  to: Scalars['Float']['output'];
};

export type IntegerRange = {
  from: Scalars['Int']['input'];
  to: Scalars['Int']['input'];
};

export type IntegerRanges = {
  from: Scalars['Int']['output'];
  to: Scalars['Int']['output'];
};

export type Location = {
  address?: Maybe<Scalars['String']['output']>;
  allBoatsCount: Scalars['Int']['output'];
  boatCategoriesIds: Array<Scalars['ID']['output']>;
  country?: Maybe<Country>;
  disableAutoChangingSlug?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  mmkName?: Maybe<Scalars['String']['output']>;
  near: Array<Location>;
  otherName?: Maybe<Scalars['String']['output']>;
  previewImages?: Maybe<LocationPreviewImage>;
  slug?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Location>;
  type: Scalars['String']['output'];
  usedAsDestination?: Maybe<Scalars['Boolean']['output']>;
};

export type LocationArgument = {
  address?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

export type LocationDescription = {
  created_at: Scalars['ISO8601DateTime']['output'];
  deContent: Scalars['String']['output'];
  default: Scalars['Boolean']['output'];
  enContent: Scalars['String']['output'];
  frContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  updated_at?: Maybe<Scalars['ISO8601DateTime']['output']>;
};

export type LocationInfo = {
  availableCategories?: Maybe<Array<BoatCategory>>;
  availableUrlCategories?: Maybe<Array<AvailableUrlCategories>>;
  captain?: Maybe<Scalars['String']['output']>;
  destinations?: Maybe<Array<Location>>;
  faqs?: Maybe<Array<Faq>>;
  location?: Maybe<Location>;
  locationDescription?: Maybe<LocationDescription>;
  selectedCategories?: Maybe<Array<BoatCategory>>;
  url?: Maybe<Scalars['String']['output']>;
  urlErrors?: Maybe<Array<UrlError>>;
};

export type LocationPreviewImage = {
  x1?: Maybe<Scalars['String']['output']>;
  x2?: Maybe<Scalars['String']['output']>;
  x3?: Maybe<Scalars['String']['output']>;
  x4?: Maybe<Scalars['String']['output']>;
};

export type Meta = {
  /** This field as default */
  page: Scalars['Int']['output'];
  /** This field as default */
  perPage: Scalars['Int']['output'];
  /** This field as default */
  totalCount: Scalars['Int']['output'];
  /** This field as default */
  totalPages: Scalars['Int']['output'];
};

export type MetaBoatTopReviews = {
  /** This field will show available categories for Location */
  availableCategories?: Maybe<Array<BoatCategory>>;
  /** This field will show url available categories */
  availableUrlCategories?: Maybe<Array<AvailableUrlCategories>>;
  /** This field will show the use of the captain filter or not. */
  captain?: Maybe<Scalars['String']['output']>;
  /** The field will be a Location. */
  location?: Maybe<Location>;
  /** This field will show Rating Location by average rating boats */
  locationAverageRating?: Maybe<Scalars['Float']['output']>;
  /** This field as default */
  page: Scalars['Int']['output'];
  /** This field as default */
  perPage: Scalars['Int']['output'];
  /** This field will show selected categories */
  selectedCategories?: Maybe<Array<BoatCategory>>;
  /** This field as default */
  totalCount: Scalars['Int']['output'];
  /** This field will show total count Reviews for Location */
  totalCountReviews?: Maybe<Scalars['Int']['output']>;
  /** This field as default */
  totalPages: Scalars['Int']['output'];
  /** The field will be an SEO URL, if changed queries (location_id or filters) it will be a new SEO URL based on these args. */
  url?: Maybe<Scalars['String']['output']>;
  /** This field will show if url has error */
  urlErrors?: Maybe<Array<UrlError>>;
};

export type MetaSeoQueries = {
  /** This field will show available categories for Location */
  availableCategories?: Maybe<Array<BoatCategory>>;
  /** This field will show url available categories */
  availableUrlCategories?: Maybe<Array<AvailableUrlCategories>>;
  /** This field will show the use of the captain filter or not. */
  captain?: Maybe<Scalars['String']['output']>;
  /** The field will be a Location. */
  location?: Maybe<Location>;
  /** This field as default */
  page: Scalars['Int']['output'];
  /** This field as default */
  perPage: Scalars['Int']['output'];
  /** This field will show selected categories */
  selectedCategories?: Maybe<Array<BoatCategory>>;
  /** This field as default */
  totalCount: Scalars['Int']['output'];
  /** This field as default */
  totalPages: Scalars['Int']['output'];
  /** The field will be an SEO URL, if changed queries (location_id or filters) it will be a new SEO URL based on these args. */
  url?: Maybe<Scalars['String']['output']>;
  /** This field will show if url has error */
  urlErrors?: Maybe<Array<UrlError>>;
};

export type Money = {
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type Mutation = {
  addBoatReview?: Maybe<BoatReview>;
  addCharter?: Maybe<Charter>;
  addPlatronPayment?: Maybe<Payment>;
  addStripePayment?: Maybe<Payment>;
  addWebPushToken?: Maybe<Success>;
  buyBoat?: Maybe<Success>;
  callMeRequest?: Maybe<Success>;
  changeUserInterest?: Maybe<UserInterest>;
  checkSignUpAfterPaySubscription?: Maybe<ResetPasswordLink>;
  createCheckoutSession: SessionUrl;
  magickAuthentication: Account;
  passwordReset: Scalars['String']['output'];
  signIn: Account;
  signUp: Account;
  signUpCa: Account;
  unsubscribeEmail?: Maybe<Success>;
  updateCharter?: Maybe<Charter>;
  updatePayment?: Maybe<Payment>;
};

export type MutationAddBoatReviewArgs = {
  accountId: Scalars['ID']['input'];
  boatId: Scalars['ID']['input'];
  deContent?: InputMaybe<Scalars['String']['input']>;
  enContent?: InputMaybe<Scalars['String']['input']>;
  frContent?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<Scalars['Float']['input']>;
};

export type MutationAddCharterArgs = {
  boatCategoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  boatId?: InputMaybe<Scalars['ID']['input']>;
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  divingEquipment?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  flexibleDates?: InputMaybe<Scalars['Boolean']['input']>;
  guests?: InputMaybe<Scalars['Int']['input']>;
  hoursFrom?: InputMaybe<Scalars['Int']['input']>;
  hoursTo?: InputMaybe<Scalars['Int']['input']>;
  jetSky?: InputMaybe<Scalars['Int']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  phones?: InputMaybe<Array<Scalars['String']['input']>>;
  priceMax?: InputMaybe<Scalars['Int']['input']>;
  priceMin?: InputMaybe<Scalars['Int']['input']>;
  promoCodeOriginal?: InputMaybe<Scalars['String']['input']>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
  transferNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmReferralLink?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  utmTrafficSource?: InputMaybe<Scalars['String']['input']>;
  watersports?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationAddPlatronPaymentArgs = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  offerId: Scalars['ID']['input'];
};

export type MutationAddStripePaymentArgs = {
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
  offerId: Scalars['ID']['input'];
};

export type MutationAddWebPushTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type MutationBuyBoatArgs = {
  boatId: Scalars['ID']['input'];
  boatLink: Scalars['String']['input'];
  boatPrice: Scalars['Float']['input'];
  boatPriceCurrency: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  userEmail: Scalars['String']['input'];
  userName: Scalars['String']['input'];
  userPhone: Scalars['String']['input'];
  userPrice?: InputMaybe<Scalars['String']['input']>;
  utms: Scalars['JSON']['input'];
};

export type MutationCallMeRequestArgs = {
  phone: Scalars['String']['input'];
};

export type MutationChangeUserInterestArgs = {
  offerId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
};

export type MutationCheckSignUpAfterPaySubscriptionArgs = {
  sessionId: Scalars['String']['input'];
};

export type MutationCreateCheckoutSessionArgs = {
  lookupKey: Scalars['String']['input'];
};

export type MutationMagickAuthenticationArgs = {
  token: Scalars['String']['input'];
};

export type MutationPasswordResetArgs = {
  accountId: Scalars['Int']['input'];
  confirmationCode: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MutationSignUpArgs = {
  cellphone?: InputMaybe<Scalars['String']['input']>;
  centralAgentRequest?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type MutationSignUpCaArgs = {
  cellphone?: InputMaybe<Scalars['String']['input']>;
  centralAgentRequest?: InputMaybe<Scalars['Boolean']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUnsubscribeEmailArgs = {
  token: Scalars['String']['input'];
};

export type MutationUpdateCharterArgs = {
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  charterLocations?: InputMaybe<Array<CharterLocationArgument>>;
  id: Scalars['ID']['input'];
};

export type MutationUpdatePaymentArgs = {
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type NullSuccess = {
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Offer = {
  account: Account;
  boat: Boat;
  centralAgent?: Maybe<CentralAgent>;
  charter: Charter;
  endAt: Scalars['ISO8601DateTime']['output'];
  firstPaymentAmount: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  isPreliminary?: Maybe<Scalars['Boolean']['output']>;
  isUserInterest?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  matches: OfferMatch;
  payTillOn: Scalars['ISO8601DateTime']['output'];
  payments: Array<Payment>;
  prepayPercent: Scalars['Float']['output'];
  price?: Maybe<Money>;
  priceWithoutDiscount?: Maybe<Money>;
  secondPaymentAmount: Scalars['Float']['output'];
  secondPaymentPercent: Scalars['Float']['output'];
  startAt: Scalars['ISO8601DateTime']['output'];
  status: OfferStatus;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
};

export type OfferMatch = {
  duration: Scalars['Boolean']['output'];
  endAt: Scalars['Boolean']['output'];
  location: Scalars['Boolean']['output'];
  startAt: Scalars['Boolean']['output'];
};

export enum OfferStatus {
  /** Canceled */
  Canceled = 'canceled',
  /** Completed */
  Completed = 'completed',
  /** Considering */
  Considering = 'considering',
  /** Created */
  Pending = 'pending',
  /** Processing */
  Processing = 'processing',
  /** Selected */
  Selected = 'selected',
}

export type Partner = {
  contacts?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  referralTag?: Maybe<Scalars['String']['output']>;
};

export type Partnership = {
  accounts: Array<RestrictAccount>;
  partner: Partner;
  promoCodes?: Maybe<Array<PromoCode>>;
};

export type Payment = {
  amount?: Maybe<Money>;
  id: Scalars['Int']['output'];
  offer: Offer;
  paymentMethod?: Maybe<PaymentMethod>;
  redirectUrl?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
  token?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type PaymentMethod = {
  id: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export enum PaymentStatus {
  /** Canceled */
  Canceled = 'canceled',
  /** Waiting */
  Created = 'created',
  /** Declined */
  Declined = 'declined',
  /** Error */
  Error = 'error',
  /** Hold */
  Hold = 'hold',
  /** Paid */
  Paid = 'paid',
  /** Processed */
  Processed = 'processed',
}

export type Picture = {
  lg?: Maybe<Scalars['String']['output']>;
  md?: Maybe<Scalars['String']['output']>;
  sm?: Maybe<Scalars['String']['output']>;
  thumb?: Maybe<Scalars['String']['output']>;
  w100?: Maybe<Scalars['String']['output']>;
  w280?: Maybe<Scalars['String']['output']>;
  w300?: Maybe<Scalars['String']['output']>;
  w500?: Maybe<Scalars['String']['output']>;
  w560?: Maybe<Scalars['String']['output']>;
  w680?: Maybe<Scalars['String']['output']>;
  w840?: Maybe<Scalars['String']['output']>;
  w1200?: Maybe<Scalars['String']['output']>;
  w1360?: Maybe<Scalars['String']['output']>;
  w2040?: Maybe<Scalars['String']['output']>;
};

export type Price = {
  price?: Maybe<Scalars['String']['output']>;
  withDiscount?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  accessAdmin: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  cellphone?: Maybe<Scalars['String']['output']>;
  centralAgentRequest: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isAdmin: Scalars['Boolean']['output'];
  isCentralAgent: Scalars['Boolean']['output'];
  isPaidSubscription?: Maybe<Scalars['Boolean']['output']>;
  isPartnerAccount?: Maybe<Scalars['Boolean']['output']>;
  isSeniorAccount?: Maybe<Scalars['Boolean']['output']>;
  paymentMethods: Array<PaymentMethod>;
};

export type PromoCode = {
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  expiredAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  id: Scalars['ID']['output'];
  promoCode?: Maybe<Scalars['String']['output']>;
  quantityForUse?: Maybe<Scalars['Int']['output']>;
  quantityUsed?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
};

export type ProtectedAccount = {
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type Query = {
  availableBoats: AvailableBoatItems;
  boat: Boat;
  boatCategories: Array<BoatCategory>;
  charter?: Maybe<Charter>;
  charters: Array<Charter>;
  chat?: Maybe<Chat>;
  checkPromoCode: PromoCode;
  currentUser: CurrentUser;
  featuredBoatsForRent: Array<Boat>;
  location: Location;
  locationInfo?: Maybe<LocationInfo>;
  locationSuggestions: Array<Location>;
  nearLocations: Array<Location>;
  offer?: Maybe<Offer>;
  offers: Array<Offer>;
  offersLink: Scalars['String']['output'];
  partnership?: Maybe<Partnership>;
  payment?: Maybe<Payment>;
  profile?: Maybe<Profile>;
  searchBoatCategories: Array<BoatCategory>;
  searchBoatReviewsByBoat: BoatReviewItems;
  searchBoats: BoatItems;
  searchBoatsMeta: SearchBoatsMeta;
  searchBoatsWithTopReview: BoatTopReviewItems;
  sendPasswordResetEmail: Scalars['String']['output'];
  stripeBillingPortal?: Maybe<BillingPortal>;
};

export type QueryAvailableBoatsArgs = {
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  captain?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  hasPhoto?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QueryBoatArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCharterArgs = {
  id: Scalars['Int']['input'];
};

export type QueryChartersArgs = {
  typeOrigin?: InputMaybe<CharterTypeOrigin>;
};

export type QueryChatArgs = {
  offerId: Scalars['Int']['input'];
};

export type QueryCheckPromoCodeArgs = {
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryFeaturedBoatsForRentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLocationArgs = {
  id: Scalars['Int']['input'];
};

export type QueryLocationInfoArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QueryLocationSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryNearLocationsArgs = {
  countryId?: InputMaybe<Scalars['ID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  locationId: Scalars['ID']['input'];
};

export type QueryOfferArgs = {
  id: Scalars['Int']['input'];
};

export type QueryOffersArgs = {
  charterId: Scalars['Int']['input'];
};

export type QueryOffersLinkArgs = {
  days: Scalars['Int']['input'];
};

export type QueryPaymentArgs = {
  id: Scalars['Int']['input'];
};

export type QuerySearchBoatCategoriesArgs = {
  location?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchBoatReviewsByBoatArgs = {
  boatId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySearchBoatsArgs = {
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']>>;
  builtAt?: InputMaybe<IntegerRange>;
  condition?: InputMaybe<Scalars['Int']['input']>;
  hasPhoto?: InputMaybe<Scalars['String']['input']>;
  length?: InputMaybe<FloatRange>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  nearLocations?: InputMaybe<BoatNearLocationFilter>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  providedFor?: InputMaybe<Scalars['Int']['input']>;
};

export type QuerySearchBoatsWithTopReviewArgs = {
  boatId?: InputMaybe<Scalars['ID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySendPasswordResetEmailArgs = {
  email: Scalars['String']['input'];
};

export type QueryStripeBillingPortalArgs = {
  returnUrl: Scalars['String']['input'];
};

export type ResetPasswordLink = {
  link: Scalars['String']['output'];
};

export type RestrictAccount = {
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isPartnerAccount?: Maybe<Scalars['Boolean']['output']>;
  isSeniorAccount?: Maybe<Scalars['Boolean']['output']>;
  roles?: Maybe<Array<Scalars['String']['output']>>;
};

export type SailingArea = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type SearchBoatsMeta = {
  builtAt: IntegerRanges;
  length: FloatRanges;
};

export type SessionUrl = {
  sessionId: Scalars['String']['output'];
  sessionUrl: Scalars['String']['output'];
};

export type Slugs = {
  country?: Maybe<Scalars['String']['output']>;
  locationAndCategory?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  charterOffers: Array<Offer>;
  checkPaidSalePlan?: Maybe<NullSuccess>;
  newOffer?: Maybe<Charter>;
  updatedCharters: Array<Charter>;
};

export type SubscriptionCharterOffersArgs = {
  charterId: Scalars['Int']['input'];
};

export type SubscriptionCheckPaidSalePlanArgs = {
  sessionId: Scalars['String']['input'];
};

export type SubscriptionNewOfferArgs = {
  id: Scalars['Int']['input'];
};

export type SubscriptionUpdatedChartersArgs = {
  typeOrigin?: InputMaybe<CharterTypeOrigin>;
};

export type Success = {
  success: Scalars['Boolean']['output'];
};

export type UrlError = {
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type UserInterest = {
  offerId?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type WorldRegion = {
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type AvailableBoatItemsFragment = {
  items: Array<{
    id: string;
    slug?: string | null;
    name?: string | null;
    builtAt?: number | null;
    rating?: number | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    boatProvidedFor?: string | null;
    location?: { address?: string | null; country?: { name: string } | null } | null;
    pictures: Array<{ md?: string | null }>;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
  }>;
  meta: {
    captain?: string | null;
    page: number;
    perPage: number;
    totalCount: number;
    totalPages: number;
    url?: string | null;
    availableCategories?: Array<{
      id: string;
      name?: string | null;
      picture?: { w85?: string | null; w170?: string | null; w255?: string | null; w340?: string | null } | null;
      price?: { amount: number } | null;
    }> | null;
    availableUrlCategories?: Array<{ id: string; url: string }> | null;
    selectedCategories?: Array<{ id: string }> | null;
    location?: {
      id: string;
      address?: string | null;
      slug?: string | null;
      type: string;
      country?: { id: string; name: string; code: string } | null;
      state?: { id: string; address?: string | null } | null;
    } | null;
  };
};

export type BoatFullFragment = {
  id: string;
  slug?: string | null;
  name?: string | null;
  rating?: number | null;
  description?: string | null;
  length?: number | null;
  persons?: number | null;
  cabins?: number | null;
  berths?: number | null;
  crewTotal?: number | null;
  builtAt?: number | null;
  boatProvidedFor?: string | null;
  cancellationPolicy?: string | null;
  slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
  location?: {
    id: string;
    address?: string | null;
    slug?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    country?: { name: string } | null;
  } | null;
  pictures: Array<{
    lg?: string | null;
    md?: string | null;
    sm?: string | null;
    thumb?: string | null;
    w680?: string | null;
    w1360?: string | null;
    w2040?: string | null;
    w100?: string | null;
    w300?: string | null;
  }>;
  boatCategory?: { id: string; name?: string | null } | null;
  exclusivePrice?: {
    currency?: string | null;
    pricePerHour?: number | null;
    pricePerDay?: number | null;
    pricePerWeek?: number | null;
  } | null;
  salePrice?: { currency?: string | null; value?: number | null } | null;
  boatAdditionalInfo?: {
    accommodationAndCrew?: any | null;
    amenities?: any | null;
    dimensions?: any | null;
    equipment?: any | null;
    generalInformation?: any | null;
    performance?: any | null;
    rentPrice?: any | null;
    tendersAndToys?: any | null;
  } | null;
  boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
};

export type BoatShortFragment = {
  id: string;
  slug?: string | null;
  name?: string | null;
  builtAt?: number | null;
  rating?: number | null;
  length?: number | null;
  persons?: number | null;
  cabins?: number | null;
  berths?: number | null;
  crewTotal?: number | null;
  boatProvidedFor?: string | null;
  location?: { address?: string | null; country?: { name: string } | null } | null;
  pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
  exclusivePrice?: {
    currency?: string | null;
    pricePerHour?: number | null;
    pricePerDay?: number | null;
    pricePerWeek?: number | null;
  } | null;
  salePrice?: { currency?: string | null; value?: number | null } | null;
};

export type BoatTopReviewItemsFragment = {
  items: Array<{
    id: string;
    rating: number;
    enContent: string;
    createdAt: any;
    boat: { id: string; name?: string | null; pictures: Array<{ md?: string | null }> };
    account: { id: number; fullName?: string | null };
  }>;
  meta: { locationAverageRating?: number | null; totalCount: number; totalCountReviews?: number | null };
};

export type CharterFragment = {
  id: number;
  startsAt?: any | null;
  endsAt?: any | null;
  promoCodeOriginal?: string | null;
  locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
  promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
  offers?: Array<{ id: number }> | null;
  boat?: {
    id: string;
    slug?: string | null;
    name?: string | null;
    rating?: number | null;
    description?: string | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    builtAt?: number | null;
    boatProvidedFor?: string | null;
    cancellationPolicy?: string | null;
    slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
    location?: {
      id: string;
      address?: string | null;
      slug?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country?: { name: string } | null;
    } | null;
    pictures: Array<{
      lg?: string | null;
      md?: string | null;
      sm?: string | null;
      thumb?: string | null;
      w680?: string | null;
      w1360?: string | null;
      w2040?: string | null;
      w100?: string | null;
      w300?: string | null;
    }>;
    boatCategory?: { id: string; name?: string | null } | null;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
    boatAdditionalInfo?: {
      accommodationAndCrew?: any | null;
      amenities?: any | null;
      dimensions?: any | null;
      equipment?: any | null;
      generalInformation?: any | null;
      performance?: any | null;
      rentPrice?: any | null;
      tendersAndToys?: any | null;
    } | null;
    boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
  } | null;
};

export type ChartersFragment = {
  id: number;
  startsAt?: any | null;
  endsAt?: any | null;
  promoCodeOriginal?: string | null;
  createdAt?: any | null;
  createdBy?: CharterCreatedByEnum | null;
  locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
  promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
  offers?: Array<{ id: number; status: OfferStatus }> | null;
};

export type LocationInfoFragment = {
  location?: { id: string; address?: string | null; slug?: string | null } | null;
  locationDescription?: { enContent: string } | null;
  faqs?: Array<{ id: string; enAnswer: string; enQuestion: string }> | null;
  destinations?: Array<{
    id: string;
    address?: string | null;
    slug?: string | null;
    previewImages?: { x1?: string | null; x2?: string | null; x3?: string | null; x4?: string | null } | null;
  }> | null;
};

export type LocationSuggestionFragment = {
  address?: string | null;
  id: string;
  type: string;
  slug?: string | null;
  country?: { id: string; name: string; code: string } | null;
};

export type OfferFragment = {
  id: number;
  prepayPercent: number;
  firstPaymentAmount: number;
  secondPaymentAmount: number;
  secondPaymentPercent: number;
  payTillOn: any;
  startAt: any;
  endAt: any;
  status: OfferStatus;
  location?: { address?: string | null; country?: { name: string } | null } | null;
  payments: Array<{
    id: number;
    status: PaymentStatus;
    type: string;
    redirectUrl?: string | null;
    token?: string | null;
    paymentMethod?: { id: number; type: string } | null;
    amount?: { amount: number; currency: string } | null;
  }>;
  price?: { amount: number; currency: string } | null;
  priceWithoutDiscount?: { amount: number; currency: string } | null;
  charter: {
    id: number;
    startsAt?: any | null;
    endsAt?: any | null;
    promoCodeOriginal?: string | null;
    guests?: number | null;
    captain: boolean;
    comment?: string | null;
    phones: Array<string>;
    promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
    locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
  };
  account: { fullName?: string | null; cellphone?: string | null; email?: string | null };
  centralAgent?: {
    accountPhone?: string | null;
    companyPhone?: string | null;
    email?: string | null;
    joinedDate?: any | null;
    name?: string | null;
  } | null;
  boat: {
    id: string;
    slug?: string | null;
    name?: string | null;
    rating?: number | null;
    description?: string | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    builtAt?: number | null;
    boatProvidedFor?: string | null;
    cancellationPolicy?: string | null;
    slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
    location?: {
      id: string;
      address?: string | null;
      slug?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country?: { name: string } | null;
    } | null;
    pictures: Array<{
      lg?: string | null;
      md?: string | null;
      sm?: string | null;
      thumb?: string | null;
      w680?: string | null;
      w1360?: string | null;
      w2040?: string | null;
      w100?: string | null;
      w300?: string | null;
    }>;
    boatCategory?: { id: string; name?: string | null } | null;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
    boatAdditionalInfo?: {
      accommodationAndCrew?: any | null;
      amenities?: any | null;
      dimensions?: any | null;
      equipment?: any | null;
      generalInformation?: any | null;
      performance?: any | null;
      rentPrice?: any | null;
      tendersAndToys?: any | null;
    } | null;
    boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
  };
};

export type BoatFragment = {
  boat?: {
    id: string;
    slug?: string | null;
    name?: string | null;
    builtAt?: number | null;
    rating?: number | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    boatProvidedFor?: string | null;
    location?: { address?: string | null; country?: { name: string } | null } | null;
    pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
  } | null;
};

export type SearchBoatsFragment = {
  id: string;
  slug?: string | null;
  name?: string | null;
  builtAt?: number | null;
  rating?: number | null;
  length?: number | null;
  persons?: number | null;
  cabins?: number | null;
  berths?: number | null;
  crewTotal?: number | null;
  boatProvidedFor?: string | null;
  location?: { address?: string | null; country?: { name: string } | null } | null;
  pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
  exclusivePrice?: {
    currency?: string | null;
    pricePerHour?: number | null;
    pricePerDay?: number | null;
    pricePerWeek?: number | null;
  } | null;
  salePrice?: { currency?: string | null; value?: number | null } | null;
};

export type CurrentUserLocalQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserLocalQuery = {
  currentUser: {
    id?: number | null;
    isLoggedIn: boolean;
    email: string;
    accessAdmin: boolean;
    isCentralAgent: boolean;
    isAdmin: boolean;
    isPaidSubscription: boolean;
    isSeniorAccount: boolean;
    isPartnerAccount: boolean;
  };
};

export type AddChareterMutationVariables = Exact<{
  phones?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  transferNeeded?: InputMaybe<Scalars['Boolean']['input']>;
  watersports?: InputMaybe<Scalars['Boolean']['input']>;
  captain?: InputMaybe<Scalars['Boolean']['input']>;
  boatCategoryIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  guests?: InputMaybe<Scalars['Int']['input']>;
  promoCodeOriginal?: InputMaybe<Scalars['String']['input']>;
  priceMax?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  lang?: InputMaybe<Scalars['String']['input']>;
  boatId?: InputMaybe<Scalars['ID']['input']>;
  startsAt?: InputMaybe<Scalars['String']['input']>;
  endsAt?: InputMaybe<Scalars['String']['input']>;
  flexibleDates?: InputMaybe<Scalars['Boolean']['input']>;
  hoursFrom?: InputMaybe<Scalars['Int']['input']>;
  hoursTo?: InputMaybe<Scalars['Int']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
  utmTrafficSource?: InputMaybe<Scalars['String']['input']>;
  utmReferralLink?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddChareterMutation = {
  addCharter?: {
    id: number;
    boat?: { id: string; name?: string | null } | null;
    price?: { amount: number; currency: string } | null;
  } | null;
};

export type AddPlatronPaymentMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
}>;

export type AddPlatronPaymentMutation = {
  addPlatronPayment?: { id: number; status: PaymentStatus; redirectUrl?: string | null } | null;
};

export type AddStripePaymentMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
  amount: Scalars['Float']['input'];
  currency: Scalars['String']['input'];
}>;

export type AddStripePaymentMutation = { addStripePayment?: { id: number; status: PaymentStatus } | null };

export type AddWebPushTokenMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;

export type AddWebPushTokenMutation = { addWebPushToken?: { success: boolean } | null };

export type BuyBoatMutationVariables = Exact<{
  userName: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
  userPhone: Scalars['String']['input'];
  userPrice?: InputMaybe<Scalars['String']['input']>;
  boatPrice: Scalars['Float']['input'];
  boatPriceCurrency: Scalars['String']['input'];
  boatId: Scalars['ID']['input'];
  boatLink: Scalars['String']['input'];
  utms: Scalars['JSON']['input'];
}>;

export type BuyBoatMutation = { buyBoat?: { success: boolean } | null };

export type CallMeRequestMutationVariables = Exact<{
  phone: Scalars['String']['input'];
}>;

export type CallMeRequestMutation = { callMeRequest?: { success: boolean } | null };

export type ChangeUserInterestMutationVariables = Exact<{
  offerId: Scalars['ID']['input'];
  value: Scalars['Boolean']['input'];
}>;

export type ChangeUserInterestMutation = {
  changeUserInterest?: { success?: boolean | null; offerId?: number | null; value?: boolean | null } | null;
};

export type CreateCheckoutSessionMutationVariables = Exact<{
  lookupKey: Scalars['String']['input'];
}>;

export type CreateCheckoutSessionMutation = { createCheckoutSession: { sessionUrl: string } };

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  accountId: Scalars['Int']['input'];
  confirmationCode: Scalars['String']['input'];
}>;

export type ResetPasswordMutation = { passwordReset: string };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type SignInMutation = {
  signIn: {
    id: number;
    token: string;
    isCentralAgent: boolean;
    isAdmin: boolean;
    isPaidSubscription?: boolean | null;
    isSeniorAccount?: boolean | null;
    isPartnerAccount?: boolean | null;
  };
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  cellphone?: InputMaybe<Scalars['String']['input']>;
}>;

export type SignUpMutation = {
  signUp: {
    id: number;
    email?: string | null;
    token: string;
    isCentralAgent: boolean;
    isAdmin: boolean;
    isPaidSubscription?: boolean | null;
  };
};

export type SignUpCaMutationVariables = Exact<{
  email: Scalars['String']['input'];
  fullName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
  cellphone?: InputMaybe<Scalars['String']['input']>;
  companyName: Scalars['String']['input'];
  vatNumber: Scalars['String']['input'];
}>;

export type SignUpCaMutation = {
  signUpCa: {
    id: number;
    email?: string | null;
    token: string;
    isCentralAgent: boolean;
    isAdmin: boolean;
    isPaidSubscription?: boolean | null;
  };
};

export type UpdatePaymentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
}>;

export type UpdatePaymentMutation = { updatePayment?: { id: number; status: PaymentStatus } | null };

export type AvailableBoatsQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars['ID']['input']>;
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  hasPhoto?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  captain?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}>;

export type AvailableBoatsQuery = {
  availableBoats: {
    items: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    }>;
    meta: {
      captain?: string | null;
      page: number;
      perPage: number;
      totalCount: number;
      totalPages: number;
      url?: string | null;
      availableCategories?: Array<{
        id: string;
        name?: string | null;
        picture?: { w85?: string | null; w170?: string | null; w255?: string | null; w340?: string | null } | null;
        price?: { amount: number } | null;
      }> | null;
      availableUrlCategories?: Array<{ id: string; url: string }> | null;
      selectedCategories?: Array<{ id: string }> | null;
      location?: {
        id: string;
        address?: string | null;
        slug?: string | null;
        type: string;
        country?: { id: string; name: string; code: string } | null;
        state?: { id: string; address?: string | null } | null;
      } | null;
    };
  };
};

export type AvailableBoatsAndLocationInfoQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars['ID']['input']>;
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  hasPhoto?: InputMaybe<Scalars['String']['input']>;
  dateFrom?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  captain?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  withInfo: Scalars['Boolean']['input'];
}>;

export type AvailableBoatsAndLocationInfoQuery = {
  availableBoats: {
    items: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    }>;
    meta: {
      captain?: string | null;
      page: number;
      perPage: number;
      totalCount: number;
      totalPages: number;
      url?: string | null;
      availableCategories?: Array<{
        id: string;
        name?: string | null;
        picture?: { w85?: string | null; w170?: string | null; w255?: string | null; w340?: string | null } | null;
        price?: { amount: number } | null;
      }> | null;
      availableUrlCategories?: Array<{ id: string; url: string }> | null;
      selectedCategories?: Array<{ id: string }> | null;
      location?: {
        id: string;
        address?: string | null;
        slug?: string | null;
        type: string;
        country?: { id: string; name: string; code: string } | null;
        state?: { id: string; address?: string | null } | null;
      } | null;
    };
  };
  locationInfo?: {
    location?: { id: string; address?: string | null; slug?: string | null } | null;
    locationDescription?: { enContent: string } | null;
    faqs?: Array<{ id: string; enAnswer: string; enQuestion: string }> | null;
    destinations?: Array<{
      id: string;
      address?: string | null;
      slug?: string | null;
      previewImages?: { x1?: string | null; x2?: string | null; x3?: string | null; x4?: string | null } | null;
    }> | null;
  } | null;
  searchBoatsWithTopReview: {
    items: Array<{
      id: string;
      rating: number;
      enContent: string;
      createdAt: any;
      boat: { id: string; name?: string | null; pictures: Array<{ md?: string | null }> };
      account: { id: number; fullName?: string | null };
    }>;
    meta: { locationAverageRating?: number | null; totalCount: number; totalCountReviews?: number | null };
  };
};

export type GetBoatQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetBoatQuery = {
  boat: {
    id: string;
    slug?: string | null;
    name?: string | null;
    rating?: number | null;
    description?: string | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    builtAt?: number | null;
    boatProvidedFor?: string | null;
    cancellationPolicy?: string | null;
    slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
    location?: {
      id: string;
      address?: string | null;
      slug?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      country?: { name: string } | null;
    } | null;
    pictures: Array<{
      lg?: string | null;
      md?: string | null;
      sm?: string | null;
      thumb?: string | null;
      w680?: string | null;
      w1360?: string | null;
      w2040?: string | null;
      w100?: string | null;
      w300?: string | null;
    }>;
    boatCategory?: { id: string; name?: string | null } | null;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
    boatAdditionalInfo?: {
      accommodationAndCrew?: any | null;
      amenities?: any | null;
      dimensions?: any | null;
      equipment?: any | null;
      generalInformation?: any | null;
      performance?: any | null;
      rentPrice?: any | null;
      tendersAndToys?: any | null;
    } | null;
    boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
  };
};

export type CharterQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type CharterQuery = {
  charter?: {
    id: number;
    startsAt?: any | null;
    endsAt?: any | null;
    promoCodeOriginal?: string | null;
    locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
    promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
    offers?: Array<{ id: number }> | null;
    boat?: {
      id: string;
      slug?: string | null;
      name?: string | null;
      rating?: number | null;
      description?: string | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      builtAt?: number | null;
      boatProvidedFor?: string | null;
      cancellationPolicy?: string | null;
      slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
      location?: {
        id: string;
        address?: string | null;
        slug?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        country?: { name: string } | null;
      } | null;
      pictures: Array<{
        lg?: string | null;
        md?: string | null;
        sm?: string | null;
        thumb?: string | null;
        w680?: string | null;
        w1360?: string | null;
        w2040?: string | null;
        w100?: string | null;
        w300?: string | null;
      }>;
      boatCategory?: { id: string; name?: string | null } | null;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
      boatAdditionalInfo?: {
        accommodationAndCrew?: any | null;
        amenities?: any | null;
        dimensions?: any | null;
        equipment?: any | null;
        generalInformation?: any | null;
        performance?: any | null;
        rentPrice?: any | null;
        tendersAndToys?: any | null;
      } | null;
      boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
    } | null;
  } | null;
};

export type ChartersQueryVariables = Exact<{
  typeOrigin?: InputMaybe<CharterTypeOrigin>;
}>;

export type ChartersQuery = {
  charters: Array<{
    id: number;
    startsAt?: any | null;
    endsAt?: any | null;
    promoCodeOriginal?: string | null;
    createdAt?: any | null;
    createdBy?: CharterCreatedByEnum | null;
    locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
    promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
    offers?: Array<{ id: number; status: OfferStatus }> | null;
  }>;
};

export type ChatQueryVariables = Exact<{
  offerId: Scalars['Int']['input'];
}>;

export type ChatQuery = { chat?: { appId: number; threadId: string; userToken: string } | null };

export type CheckPromoCodeQueryVariables = Exact<{
  promoCode?: InputMaybe<Scalars['String']['input']>;
}>;

export type CheckPromoCodeQuery = {
  checkPromoCode: { discountPercentage?: number | null; promoCode?: string | null; status: string };
};

export type ForLandingPageQueryVariables = Exact<{
  locationId?: InputMaybe<Scalars['ID']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ForLandingPageQuery = {
  featuredBoatsForRent: Array<{
    id: string;
    slug?: string | null;
    name?: string | null;
    builtAt?: number | null;
    rating?: number | null;
    length?: number | null;
    persons?: number | null;
    cabins?: number | null;
    berths?: number | null;
    crewTotal?: number | null;
    boatProvidedFor?: string | null;
    location?: { address?: string | null; country?: { name: string } | null } | null;
    pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
    exclusivePrice?: {
      currency?: string | null;
      pricePerHour?: number | null;
      pricePerDay?: number | null;
      pricePerWeek?: number | null;
    } | null;
    salePrice?: { currency?: string | null; value?: number | null } | null;
  }>;
  rentBoats: {
    items: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    }>;
  };
  saleBoats: {
    items: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    }>;
  };
};

export type GetOfferByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type GetOfferByIdQuery = {
  offer?: {
    id: number;
    prepayPercent: number;
    firstPaymentAmount: number;
    secondPaymentAmount: number;
    secondPaymentPercent: number;
    payTillOn: any;
    startAt: any;
    endAt: any;
    status: OfferStatus;
    location?: { address?: string | null; country?: { name: string } | null } | null;
    payments: Array<{
      id: number;
      status: PaymentStatus;
      type: string;
      redirectUrl?: string | null;
      token?: string | null;
      paymentMethod?: { id: number; type: string } | null;
      amount?: { amount: number; currency: string } | null;
    }>;
    price?: { amount: number; currency: string } | null;
    priceWithoutDiscount?: { amount: number; currency: string } | null;
    charter: {
      id: number;
      startsAt?: any | null;
      endsAt?: any | null;
      promoCodeOriginal?: string | null;
      guests?: number | null;
      captain: boolean;
      comment?: string | null;
      phones: Array<string>;
      promoCode?: { discountPercentage?: number | null; promoCode?: string | null } | null;
      locations: Array<{ id: string; name?: string | null; location?: { id: string; address?: string | null } | null }>;
    };
    account: { fullName?: string | null; cellphone?: string | null; email?: string | null };
    centralAgent?: {
      accountPhone?: string | null;
      companyPhone?: string | null;
      email?: string | null;
      joinedDate?: any | null;
      name?: string | null;
    } | null;
    boat: {
      id: string;
      slug?: string | null;
      name?: string | null;
      rating?: number | null;
      description?: string | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      builtAt?: number | null;
      boatProvidedFor?: string | null;
      cancellationPolicy?: string | null;
      slugs?: { country?: string | null; locationAndCategory?: string | null } | null;
      location?: {
        id: string;
        address?: string | null;
        slug?: string | null;
        latitude?: number | null;
        longitude?: number | null;
        country?: { name: string } | null;
      } | null;
      pictures: Array<{
        lg?: string | null;
        md?: string | null;
        sm?: string | null;
        thumb?: string | null;
        w680?: string | null;
        w1360?: string | null;
        w2040?: string | null;
        w100?: string | null;
        w300?: string | null;
      }>;
      boatCategory?: { id: string; name?: string | null } | null;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
      boatAdditionalInfo?: {
        accommodationAndCrew?: any | null;
        amenities?: any | null;
        dimensions?: any | null;
        equipment?: any | null;
        generalInformation?: any | null;
        performance?: any | null;
        rentPrice?: any | null;
        tendersAndToys?: any | null;
      } | null;
      boatProducts?: Array<{ id: string; productName: string; priceEur: string; unit: string }> | null;
    };
  } | null;
};

export type LocationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type LocationQuery = {
  location: {
    address?: string | null;
    longitude?: number | null;
    latitude?: number | null;
    type: string;
    country?: { name: string } | null;
  };
};

export type LocationInfoQueryVariables = Exact<{
  url?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
}>;

export type LocationInfoQuery = {
  locationInfo?: {
    location?: { id: string; address?: string | null; slug?: string | null } | null;
    locationDescription?: { enContent: string } | null;
    faqs?: Array<{ id: string; enAnswer: string; enQuestion: string }> | null;
    destinations?: Array<{
      id: string;
      address?: string | null;
      slug?: string | null;
      previewImages?: { x1?: string | null; x2?: string | null; x3?: string | null; x4?: string | null } | null;
    }> | null;
  } | null;
  searchBoatsWithTopReview: {
    items: Array<{
      id: string;
      rating: number;
      enContent: string;
      createdAt: any;
      boat: { id: string; name?: string | null; pictures: Array<{ md?: string | null }> };
      account: { id: number; fullName?: string | null };
    }>;
    meta: { locationAverageRating?: number | null; totalCount: number; totalCountReviews?: number | null };
  };
};

export type LocationSuggestionsQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type LocationSuggestionsQuery = {
  locationSuggestions: Array<{
    address?: string | null;
    id: string;
    type: string;
    slug?: string | null;
    country?: { id: string; name: string; code: string } | null;
  }>;
};

export type OffersQueryVariables = Exact<{
  charterId: Scalars['Int']['input'];
}>;

export type OffersQuery = {
  offers: Array<{
    id: number;
    firstPaymentAmount: number;
    payTillOn: any;
    prepayPercent: number;
    secondPaymentAmount: number;
    secondPaymentPercent: number;
    startAt: any;
    endAt: any;
    isPreliminary?: boolean | null;
    isUserInterest?: boolean | null;
    status: OfferStatus;
    boat: {
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    };
    price?: { amount: number; currency: string } | null;
    priceWithoutDiscount?: { amount: number; currency: string } | null;
    payments: Array<{
      id: number;
      status: PaymentStatus;
      type: string;
      amount?: { amount: number; currency: string } | null;
    }>;
  }>;
};

export type OffersLinkQueryVariables = Exact<{
  days: Scalars['Int']['input'];
}>;

export type OffersLinkQuery = { offersLink: string };

export type PartnershipQueryVariables = Exact<{ [key: string]: never }>;

export type PartnershipQuery = {
  partnership?: {
    partner: {
      id: string;
      name?: string | null;
      email?: string | null;
      contacts?: string | null;
      phone?: string | null;
      referralTag?: string | null;
    };
    promoCodes?: Array<{
      id: string;
      promoCode?: string | null;
      discountPercentage?: number | null;
      quantityForUse?: number | null;
      quantityUsed?: number | null;
      expiredAt?: any | null;
      status: string;
    }> | null;
    accounts: Array<{
      id: number;
      isSeniorAccount?: boolean | null;
      isPartnerAccount?: boolean | null;
      email?: string | null;
      fullName?: string | null;
      roles?: Array<string> | null;
    }>;
  } | null;
};

export type PaymentQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;

export type PaymentQuery = {
  payment?: {
    id: number;
    status: PaymentStatus;
    token?: string | null;
    redirectUrl?: string | null;
    amount?: { amount: number; currency: string } | null;
    paymentMethod?: { id: number; type: string } | null;
    offer: {
      id: number;
      charter: {
        id: number;
        locations: Array<{ id: string; name?: string | null; location?: { address?: string | null } | null }>;
      };
      price?: { amount: number; currency: string } | null;
    };
  } | null;
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
  profile?: {
    accessAdmin: boolean;
    id: number;
    email: string;
    active: boolean;
    isCentralAgent: boolean;
    isAdmin: boolean;
    isPaidSubscription?: boolean | null;
    isSeniorAccount?: boolean | null;
    isPartnerAccount?: boolean | null;
  } | null;
};

export type SearchBoatCategoriesQueryVariables = Exact<{
  location?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchBoatCategoriesQuery = {
  searchBoatCategories: Array<{
    id: string;
    name?: string | null;
    picture?: {
      w85?: string | null;
      w170?: string | null;
      w255?: string | null;
      w340?: string | null;
      w154?: string | null;
      w308?: string | null;
      w462?: string | null;
      w616?: string | null;
    } | null;
    price?: { amount: number; currency: string } | null;
  }>;
};

export type SearchBoatsQueryVariables = Exact<{
  boatCategories?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  builtAt?: InputMaybe<IntegerRange>;
  length?: InputMaybe<FloatRange>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationName?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  condition?: InputMaybe<Scalars['Int']['input']>;
  providedFor?: InputMaybe<Scalars['Int']['input']>;
}>;

export type SearchBoatsQuery = {
  searchBoats: {
    items: Array<{
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    }>;
    meta: { totalCount: number };
  };
};

export type SearchLocationQueryVariables = Exact<{
  name: Scalars['String']['input'];
}>;

export type SearchLocationQuery = {
  locationSuggestions: Array<{
    address?: string | null;
    allBoatsCount: number;
    boatCategoriesIds: Array<string>;
    id: string;
    country?: { id: string; name: string } | null;
    near: Array<{ id: string; address?: string | null }>;
  }>;
};

export type SendPasswordResetEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;

export type SendPasswordResetEmailQuery = { sendPasswordResetEmail: string };

export type StripeBillingPortalQueryVariables = Exact<{
  returnUrl: Scalars['String']['input'];
}>;

export type StripeBillingPortalQuery = { stripeBillingPortal?: { redirectUrl?: string | null } | null };

export type CharterOffersSubscriptionVariables = Exact<{
  charterId: Scalars['Int']['input'];
}>;

export type CharterOffersSubscription = {
  charterOffers: Array<{
    id: number;
    firstPaymentAmount: number;
    payTillOn: any;
    prepayPercent: number;
    secondPaymentAmount: number;
    secondPaymentPercent: number;
    startAt: any;
    endAt: any;
    isPreliminary?: boolean | null;
    isUserInterest?: boolean | null;
    status: OfferStatus;
    boat: {
      id: string;
      slug?: string | null;
      name?: string | null;
      builtAt?: number | null;
      rating?: number | null;
      length?: number | null;
      persons?: number | null;
      cabins?: number | null;
      berths?: number | null;
      crewTotal?: number | null;
      boatProvidedFor?: string | null;
      location?: { address?: string | null; country?: { name: string } | null } | null;
      pictures: Array<{ md?: string | null; w280?: string | null; w560?: string | null; w840?: string | null }>;
      exclusivePrice?: {
        currency?: string | null;
        pricePerHour?: number | null;
        pricePerDay?: number | null;
        pricePerWeek?: number | null;
      } | null;
      salePrice?: { currency?: string | null; value?: number | null } | null;
    };
    price?: { amount: number; currency: string } | null;
    priceWithoutDiscount?: { amount: number; currency: string } | null;
    payments: Array<{
      id: number;
      status: PaymentStatus;
      type: string;
      amount?: { amount: number; currency: string } | null;
    }>;
  }>;
};

export type CheckPaidSalePlanSubscriptionVariables = Exact<{
  sessionId: Scalars['String']['input'];
}>;

export type CheckPaidSalePlanSubscription = { checkPaidSalePlan?: { success?: boolean | null } | null };

export const namedOperations = {
  Query: {
    CurrentUserLocal: 'CurrentUserLocal',
    availableBoats: 'availableBoats',
    availableBoatsAndLocationInfo: 'availableBoatsAndLocationInfo',
    getBoat: 'getBoat',
    charter: 'charter',
    charters: 'charters',
    chat: 'chat',
    checkPromoCode: 'checkPromoCode',
    forLandingPage: 'forLandingPage',
    getOfferById: 'getOfferById',
    location: 'location',
    locationInfo: 'locationInfo',
    locationSuggestions: 'locationSuggestions',
    offers: 'offers',
    offersLink: 'offersLink',
    partnership: 'partnership',
    payment: 'payment',
    profile: 'profile',
    searchBoatCategories: 'searchBoatCategories',
    searchBoats: 'searchBoats',
    searchLocation: 'searchLocation',
    sendPasswordResetEmail: 'sendPasswordResetEmail',
    stripeBillingPortal: 'stripeBillingPortal',
  },
  Mutation: {
    addChareter: 'addChareter',
    addPlatronPayment: 'addPlatronPayment',
    addStripePayment: 'addStripePayment',
    addWebPushToken: 'addWebPushToken',
    buyBoat: 'buyBoat',
    callMeRequest: 'callMeRequest',
    changeUserInterest: 'changeUserInterest',
    createCheckoutSession: 'createCheckoutSession',
    resetPassword: 'resetPassword',
    signIn: 'signIn',
    signUp: 'signUp',
    signUpCa: 'signUpCa',
    updatePayment: 'updatePayment',
  },
  Subscription: {
    charterOffers: 'charterOffers',
    checkPaidSalePlan: 'checkPaidSalePlan',
  },
  Fragment: {
    AvailableBoatItems: 'AvailableBoatItems',
    BoatFull: 'BoatFull',
    BoatShort: 'BoatShort',
    BoatTopReviewItems: 'BoatTopReviewItems',
    Charter: 'Charter',
    Charters: 'Charters',
    LocationInfo: 'LocationInfo',
    LocationSuggestion: 'LocationSuggestion',
    Offer: 'Offer',
    Boat: 'Boat',
    SearchBoats: 'SearchBoats',
  },
};
