import { useLocation } from 'react-router-dom';

import { parseUrlQuery } from '$utils/parseUrlQuery';

type UtmTagsType = {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
};

interface IGet {
  (): {
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
    utmContent?: string;
    utmTerm?: string;
    utmTrafficSource?: string | null;
    utmReferralLink?: string;
  } | null;
}

interface ISet {
  (): void;
}

function useUtmTags(): { get: IGet; set: ISet } {
  const query = parseUrlQuery(useLocation().search);

  const get = () => {
    const utmTags: UtmTagsType = JSON.parse(localStorage.getItem('utmTags') || '{}');

    if (Object.keys(utmTags).length > 0) {
      return {
        utmMedium: utmTags.utm_medium,
        utmSource: utmTags.utm_source,
        utmCampaign: utmTags.utm_campaign,
        utmContent: utmTags.utm_content,
        utmTerm: utmTags.utm_term,
        utmTrafficSource: localStorage.getItem('trafficSource'),
        utmReferralLink: utmTags.utm_medium,
      };
    }

    return null;
  };

  const set = () => {
    if (document.referrer.length > 0) {
      const url = new URL(document.referrer);

      if (url.hostname !== document.domain) {
        localStorage.setItem('trafficSource', document.referrer);
      }
    }

    if (query) {
      Object.keys(query).forEach((key) => {
        if (!['utm_medium', 'utm_source', 'utm_campaign', 'utm_content', 'utm_term'].includes(key)) {
          delete query[key];
        }
      });

      if (Object.keys(query).length > 0) {
        localStorage.setItem('utmTags', JSON.stringify(query));
      }
    }
  };

  return { get, set };
}

export default useUtmTags;
