import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import MediaQuery from 'react-responsive';
import { Helmet } from 'react-helmet-async';
import { Button, Form, Input, message, Layout } from '$ui/index';
import cn from 'classnames';

import useGeneratePath from '$hooks/useGeneratePath';
import { routes } from '$router/index';
import useSession from '$stores/session';
import { parseUrlQuery } from '$utils/parseUrlQuery';
import { GraphQL } from '$graphql/client';
import { useSignUpMutation, useSignUpCaMutation } from '$graphql/hooks';
import { userVar } from '$graphql/local/vars';

import { Form as AbstractForm, Swiper, SwiperSlide } from '$components/index';
import { FloatInputLabel, InputPhone } from '$components/Forms';

import { HandleSignUp, IValidation, SignUpProps, SignUpSearchParams } from './types';
import { steps } from './data';
import iconSteps from './assats/steps.svg';
import FormStyles from '$components/abstract/Form.module.less';
import styles from './SignUpPage.module.less';

const SignUpPage = (props: SignUpProps) => {
  const { i18n, t } = useTranslation();
  const { generatePath } = useGeneratePath();
  const history = useHistory();
  const location = useLocation();
  const urlQuery = parseUrlQuery(location.search) as SignUpSearchParams;

  const [fetchSignUp] = useSignUpMutation({ errorPolicy: 'all' });
  const [fetchSignUpCa] = useSignUpCaMutation({ errorPolicy: 'all' });

  const { initSession, destroySession } = useSession();
  const [loading, setLoading] = useState(false);
  const [validation, setValidation] = useState<IValidation>({});
  const sendDataToGTM = useGTMDispatch();

  const isCentralAgent = urlQuery?.iam === 'ca' || !!props.isCentralAgent;
  const defaultCompanyName = 'No name';

  useEffect(() => {
    destroySession();
  }, [destroySession]);

  // Temporarily. Disable field password confirmation // { email, password, passwordConfirmation }
  const signUp = ({ email, fullName, cellphone, password, companyName, vatNumber }: HandleSignUp) => {
    const afterServerResponse = (
      data?: {
        id: number;
        email?: string | null;
        token: string;
        isCentralAgent?: boolean;
        isAdmin?: boolean;
        isPaidSubscription?: boolean | null;
      },
      errors?: readonly GraphQLError[] | undefined
    ) => {
      if (data?.token) {
        initSession(data.token);
        userVar({
          id: data.id,
          email,
          isLoggedIn: true,
          accessAdmin: isCentralAgent, // TODO 2023 возможно стоит переделать на data.accessAdmin
          isCentralAgent: !!data.isCentralAgent,
          isAdmin: !!data.isAdmin,
          isPaidSubscription: !!data.isPaidSubscription,
          isSeniorAccount: false,
          isPartnerAccount: false,
        });

        if (!isCentralAgent) {
          sendDataToGTM({ event: 'submit_ga_signup_page_form' });
        }

        message.success(t('frontend.sign_up.success_form'));

        if (props.type === 'request') {
          return props.request();
        } else if (data?.isCentralAgent || data?.isAdmin) {
          if (window.location.hostname === 'staging.getboat.com' || window.location.hostname === 'localhost') {
            window.location.href = 'https://staging.getboat.com/admin';
            return;
          }

          window.location.href = 'https://getboat.com/admin';
          return;
        }

        history.push(generatePath(routes.ROOT));
        return;
      }

      if (errors) {
        const extensions: GraphQLError['extensions'] = GraphQL.formatErrors(errors, 'signUp');

        if (extensions?.validation) {
          setValidation(extensions.validation);

          if (extensions.validation?.base) {
            message.error(extensions.validation?.base);
          }
        }
      }
    };

    setLoading(true);
    setValidation({});

    if (isCentralAgent) {
      fetchSignUpCa({
        variables: {
          email,
          fullName,
          cellphone: cellphone ? cellphone : props.cellPhone,
          password,
          passwordConfirmation: password,
          companyName: companyName ? companyName : defaultCompanyName,
          vatNumber: vatNumber ? vatNumber : '',
        },
      })
        .then(({ data, errors }) => {
          afterServerResponse(data?.signUpCa, errors);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      fetchSignUp({
        variables: {
          email,
          fullName,
          cellphone: cellphone ? cellphone : props.cellPhone,
          password,
          passwordConfirmation: password,
        },
      })
        .then(({ data, errors }) => {
          afterServerResponse(data?.signUp, errors);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign-up page - getboat.com</title>
        <meta
          name="description"
          content="Sign up for a free account today to access boat rentals, yacht charters, and exclusive offers."
        />
      </Helmet>

      <Layout style={props.type === 'request' ? { background: '#fff' } : { padding: '5% 0' }} data-cy="SignUpPage">
        <div className={cn(styles.layout, isCentralAgent && styles.layoutCA)}>
          {isCentralAgent && props.type !== 'request' && (
            <div className={styles.info}>
              <div className={styles.title}>More Bookings</div>
              <div className={styles.subTitle}>Less Work</div>
              <div className={styles.description}>
                Our team is available 24/7 to provide assistance and support. Feel free to contact us at{' '}
                <a href="mailto:info@getboat.com">info@getboat.com</a>.
              </div>

              <MediaQuery maxWidth={767}>
                <Swiper spaceBetween={0} isNavigation={false} isPagination={true} className={cn(styles.steps)}>
                  {steps.map((step) => (
                    <SwiperSlide key={step.key}>
                      <div className={styles.step}>
                        <div className={styles.icon}>
                          <svg>
                            <use xlinkHref={iconSteps + '#' + step.icon} />
                          </svg>
                        </div>
                        <div className={styles.title}>{step.title}</div>
                        <div className={styles.description}>{step.description}</div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </MediaQuery>
              <MediaQuery minWidth={768}>
                <div className={styles.steps}>
                  {steps.map((step) => (
                    <div className={styles.step} key={step.key}>
                      <div className={styles.icon}>
                        <svg>
                          <use xlinkHref={iconSteps + '#' + step.icon} />
                        </svg>
                      </div>
                      <div className={styles.text}>
                        <div className={styles.title}>{step.title}</div>
                        <div className={styles.description}>{step.description}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </MediaQuery>
            </div>
          )}

          <AbstractForm
            onFinish={(form) => signUp(form as HandleSignUp)}
            onFinishFailed={console.log}
            title={!props.isModal ? t('frontend.sign_up.title') : undefined}
            isModal={props.isModal}
            classNameWrapper={styles.form}
            className="ga_signup_page_form"
          >
            <Form.Item shouldUpdate className={styles.wrapperInput}>
              {(form) => (
                <Form.Item
                  name="email"
                  extra={
                    typeof validation['email'] === 'object' && validation['email'][0] === 'has already been taken' ? (
                      <div>
                        There is an account already. Please{' '}
                        <Link to={generatePath(routes.SIGN_IN)}>{t('frontend.sign_in.title')}</Link>
                      </div>
                    ) : (
                      validation['email']
                    )
                  }
                  initialValue={props.email}
                  rules={[{ required: true, message: 'Please input your email!' }]}
                  data-cy="email"
                >
                  <FloatInputLabel
                    label={t('frontend.sign_up.form.email.placeholder')}
                    isFloated={!!form.getFieldValue('email')}
                  >
                    <Input
                      value={form.getFieldValue('email')}
                      onChange={(e) => form.setFieldsValue({ email: e.currentTarget.value })}
                      autoComplete="username"
                      autoCapitalize="none"
                      spellCheck="false"
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>

            <Form.Item shouldUpdate className={styles.wrapperInput}>
              {(form) => (
                <Form.Item name="fullName" extra={validation['fullName']} data-cy="fullName">
                  <FloatInputLabel
                    label={t('frontend.sign_up.form.name.placeholder')}
                    isFloated={!!form.getFieldValue('fullName')}
                  >
                    <Input
                      value={form.getFieldValue('fullName')}
                      onChange={(e) => form.setFieldsValue({ fullName: e.currentTarget.value })}
                      autoCapitalize="none"
                      spellCheck="false"
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>

            {/*{!props.cellPhone && (*/}
            <Form.Item shouldUpdate className={cn(styles.wrapperInput, styles.wrapperPhone)}>
              {(form) => (
                <Form.Item
                  name="cellphone"
                  extra={validation['cellphone']}
                  initialValue={props.cellPhone ? '+' + props.cellPhone : ''}
                  data-cy="cellphone"
                >
                  <FloatInputLabel
                    label={t('frontend.sign_up.form.phone.placeholder')}
                    labelFloatedStyles={{ marginLeft: '92px' }}
                    isFloated
                  >
                    <InputPhone
                      value={form.getFieldValue('cellphone')}
                      onChange={(value) => form.setFieldsValue({ cellphone: value })}
                      language={i18n.language}
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>
            {/*)}*/}

            <Form.Item shouldUpdate className={styles.wrapperInput}>
              {(form) => (
                <Form.Item
                  name="password"
                  extra={validation['password']}
                  rules={[{ required: true, message: 'Please input your password!' }]}
                  data-cy="password"
                >
                  <FloatInputLabel
                    label={t('frontend.sign_up.form.password.placeholder')}
                    isFloated={!!form.getFieldValue('password')}
                  >
                    <Input.Password
                      value={form.getFieldValue('password')}
                      onChange={(e) => form.setFieldsValue({ password: e.currentTarget.value })}
                      autoComplete="new-password"
                      autoCapitalize="none"
                      spellCheck="false"
                    />
                  </FloatInputLabel>
                </Form.Item>
              )}
            </Form.Item>

            {isCentralAgent && (
              <>
                <Form.Item shouldUpdate className={styles.wrapperInput}>
                  {(form) => (
                    <Form.Item name="companyName" extra={validation['companyName']} data-cy="companyName">
                      <FloatInputLabel label="Company name" isFloated>
                        <Input
                          value={form.getFieldValue('companyName')}
                          onChange={(e) => form.setFieldsValue({ companyName: e.currentTarget.value })}
                          autoCapitalize="none"
                          spellCheck="false"
                          placeholder={defaultCompanyName}
                        />
                      </FloatInputLabel>
                    </Form.Item>
                  )}
                </Form.Item>
                <Form.Item shouldUpdate className={styles.wrapperInput}>
                  {(form) => (
                    <Form.Item name="vatNumber" extra={validation['vatNumber']} data-cy="vatNumber">
                      <FloatInputLabel label="VAT/TIN number(optional)" isFloated={!!form.getFieldValue('vatNumber')}>
                        <Input
                          value={form.getFieldValue('vatNumber')}
                          onChange={(e) => form.setFieldsValue({ vatNumber: e.currentTarget.value })}
                          autoCapitalize="none"
                          spellCheck="false"
                        />
                      </FloatInputLabel>
                    </Form.Item>
                  )}
                </Form.Item>
              </>
            )}

            {/* // Temporarily. Disable field password confirmation
            <Form.Item
              extra={validation['password_confirmation']}
              name="passwordConfirmation"
              rules={[{ required: true, message: 'Please confirm your password!' }]}
            >
              <Input.Password placeholder={t('frontend.sign_up.form.password_confirmation.placeholder')}/>
            </Form.Item>
            */}
            <div className={styles.description}>
              <span>
                {t('frontend.sign_up.form.by_selecting')} <strong>{t('frontend.sign_up.form.submit')}</strong>{' '}
                {t('frontend.sign_up.form.below_i_agree')}{' '}
                <a href="https://getboat.com/yachts/privacy-policy/" target="_blank" rel="noreferrer">
                  {t('frontend.footer.site_map.privacy_policy')}
                </a>
                ,{' '}
                {isCentralAgent ? (
                  <a href="https://getboat.com/yachts/service-license-contract/" target="_blank" rel="noreferrer">
                    Service license contract
                  </a>
                ) : (
                  <a href="https://getboat.com/yachts/service-agreement/" target="_blank" rel="noreferrer">
                    Service agreement
                  </a>
                )}{' '}
                {t('frontend.sign_up.form.and')}{' '}
                <a
                  href="https://getboat.com/yachts/consent-to-personal-data-processing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('frontend.footer.site_map.data_processing')}
                </a>
              </span>
            </div>
            <Form.Item style={{ marginTop: 10 }} data-cy="submit">
              <Button
                type="primary"
                disabled={loading}
                className={cn('btn', 'primary', FormStyles.btn, 'ga_signup_page_send_button')}
                htmlType="submit"
              >
                {t('frontend.sign_up.form.submit')}
              </Button>
            </Form.Item>

            {!props.isModal && (
              <p className={FormStyles.additional}>
                {t('frontend.sign_up.login.already')}{' '}
                <Link to={generatePath(routes.SIGN_IN)}>{t('frontend.sign_up.login.sign_in')}</Link>
              </p>
            )}
          </AbstractForm>
        </div>
      </Layout>
    </>
  );
};

export default SignUpPage;
